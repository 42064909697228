/* eslint-disable no-console, camelcase, no-param-reassign, no-restricted-syntax,
no-underscore-dangle */
//
import _ from 'lodash';

const MAX_ATTEMPTS_FOR_FIRST_FETCH = 5;

export default {
  data() {
    return {
      loaded: false,
      erred: false,
      entries: [],
      refetchUri: undefined,
      nextPageUri: undefined,
    };
  },
  async mounted() {
    this.erred = false;
    this.entries = [];
    await this.updateList();
    // on fresh page load on /app, SC doesn't seem to be initialized
    this.loaded = true;
  },
  methods: {
    async updateList(uri = this.uri) {
      const filteredNewTracks = await this.fetchNewData.call(this, uri);
      this.entries = this.entries.concat(filteredNewTracks);
    },
    async fetchNewData(uri, attempt_number = 1) {
      let entries;
      try {
        entries = await this.fetchTracks.call(this, uri);
      } catch (err) {
        global.throwErr(err, 'Could not load next page');
        this.erred = true;
        return [];
      }

      if (entries.future_href && entries.next_href) {
        this.refetchUri = entries.future_href;
        this.nextPageUri = entries.next_href;

        if ((!entries || !entries.collection || entries.collection.length === 0) && attempt_number < MAX_ATTEMPTS_FOR_FIRST_FETCH) {
          return this.fetchNewData(this.nextPageUri, attempt_number + 1);
        }
      }

      try {
        return await this.filterData(entries);
      } catch (err) {
        global.throwErr(err, 'Could not load next page');
        this.erred = true;
        return [];
      }
    },
    async refresh() {
      if (this.loaded === false && this.erred === false) return;
      this.loaded = false;
      this.erred = false;
      this.entries = [];
      await this.updateList(this.refetchUri);
      this.loaded = true;
    },

    playTrack(entryId) {
      bus.$emit('autoplay', entryId);
    },

    playerEnded(endedEntryId) {
      // broadcast for the next entry in the list to play
      const nextEntry = this.entries[_.findIndex(this.entries, { uuid: endedEntryId }) + 1];

      if (nextEntry) {
        switch (nextEntry.kind) {
          case 'track': this.playTrack(nextEntry.uuid); break;
          case 'playlist':
            // HACK: we can't access the vue component directly, so we have it in `entry.component`
            if (nextEntry.component.playlistExpanded) {
              this.playTrack(nextEntry.uuid);
            } else {
              // if playlist is unopened, let's try to play the next entry in this list
              this.playerEnded(nextEntry.uuid);
            }
            break;
          default: break;
        }
      } else if (typeof this.loadNextPage === 'function') {
        // load the next page
        this.loadNextPage()
          .then(() => this.playerEnded(endedEntryId)) // and try again
          .catch((err) => global.throwErr(err, 'Could not load next page'));
      // if we are at the end of a playlist, let's play out of it...
      } else if (this.$parent.isPlaylist) {
        // this.playerEnded.call(this.$parent.$parent, this.$parent.entry.uuid);
        this.$emit('ended');
        // apply the parent entry's parent list to this.playerEnded with the entry id of
        // the parent entry as `endedEntryId`
      }
    },
  },
};
