<template>
  <div id="activities-list">
    <div v-if="!loaded">Loading stream...</div>
    <ul v-if="entries.length" class="track-list -no-bullets">
      <entry v-for="entry in entries" :entry="entry" :type="entry.type" @ended="playerEnded" list-name="activities-list"></entry>
    </ul>
    <blank-state v-else-if="loaded && !erred">There are currently no tracks in your stream.<br>
    <a href="https://soundcloud.com" target="_blank">Follow artists on SoundCloud</a></blank-state>

    <div v-if="erred">Error loading stream</div>
    <div v-show="loaded" class="load-more" v-cloak>
      <a @click="loadNextPage">{{ erred ? 'Try again' : 'Load more' }}</a>
      <span class="loading" v-show="loadingMore"></span>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console, no-param-reassign */
import Entry from './components/Entry';
import List from './List';
import BlankState from '../../misc/BlankState';
//

function removeDuplicateReposts(tracks) {
  const seen = [];
  let prev;
  return tracks.filter((track) => {
    if (track.type !== 'track-repost' && track.type !== 'playlist-repost') return true;

    track.numReposts = 1;

    // if track is a duplicate, edit the track with the first index of the track in `seen`
    prev = tracks[seen.indexOf(track.id)];
    if (prev) prev.numReposts++;

    seen.push(track.id);
    return !prev; // keep the track iff not seen yet
  });
}

function isOneTrackPlaylist(entry) {
  return ['playlist', 'playlist-repost'].includes(entry.type)
    && entry.track_count === 1;
}

async function collapseOneTrackPlaylist(entry) {
  const playlistTitle = entry.title;
  entry = (await SC.get(entry.tracks_uri.replace(global.apiBaseUrlRgx, ''), {
    limit: 1,
  }))[0];
  if (!entry) return null;
  entry.playlist_title = playlistTitle;
  entry.type = 'track';
  entry.collapsed = true;
  return entry;
}

export default {
  name: 'ActivitiesList',
  data() {
    return {
      loadingMore: false,
      simplifyOneTrackPlaylists: global.userSettings.get('modes').simplifyOneTrackPlaylists,
    };
  },
  props: {
    uri: { default: '/me/activities' },
  },
  mixins: [List],
  created() {
    bus.bindAll(this, {
      'activities-list:refresh': this.refresh,
    });
  },
  beforeDestroy() {
    bus.unbindAll(this);
  },
  methods: {
    onEntryUpdateAdded({ entry, newStatus }) {
      bus.$emit('entry:updateAdded', { entry, newStatus });
    },
    // for List
    fetchTracks(uri) {
      // if `uri` is a fully qualified url
      // (e.g. `refetchUri` or `nextPageUri`)
      if (global.apiBaseUrlRgx.test(uri)) {
        uri = uri.replace(global.apiBaseUrlRgx, '');
        return SC.get(`${uri}&format=json`);
      }

      return SC.get(uri, {
        limit: 20
      });
    },
    async filterData(activities) {
      let newTracks = activities.collection
        .map(({ origin, type }) => (!origin ? null : Object.assign(origin, { type })))
        .filter((a) => a !== null)
        .filter(({ streamable }) => (streamable));
      newTracks = removeDuplicateReposts(newTracks);

      if (this.simplifyOneTrackPlaylists) {
        for (let i = 0; i < newTracks.length; i++) {
          if (isOneTrackPlaylist(newTracks[i])) {
            newTracks[i] = await collapseOneTrackPlaylist(newTracks[i]);
          }
        }
        newTracks = newTracks
          .filter((a) => a !== null)
          .filter(({ streamable }) => (streamable));
      }

      return newTracks;
    },
    async loadNextPage() {
      if (this.loadingMore === true && this.erred === false) return;
      this.loadingMore = true;
      this.erred = false;
      await this.updateList(this.nextPageUri);
      this.loadingMore = false;
    },
  },
  components: { Entry, BlankState },
};
</script>

<style lang="scss" scoped>
#activities-list {
  width: 65%;
  min-width: 20rem;
  max-width: 37rem;
  margin: auto;
}

.track-list {
  padding: 0;
}

.track-list > li {
  margin: 1em 0 2em 0;
}

.load-more {
  position: relative;
  width: 100%;
  text-align: center;
  > a {
    cursor: pointer;
  }
}
</style>