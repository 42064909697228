<template>
  <div class="navitem">
    <router-link v-if="link" :to="link" tag="button" v-bind:class="accent ? 'accent' : ''">
      <span v-text="title"></span>
    </router-link>
    <button v-else-if="globalEvent" @click="trigger" v-bind:class="accent ? 'accent' : ''">
      <span v-text="title"></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'NavButton',
  props: ['link', 'globalEvent', 'title', 'accent'],
  methods: {
    trigger() {
      bus.$emit(this.globalEvent);
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  padding: 0.5em 0.4em;
}
</style>