<template>
  <li v-show="!hidden" :class="listName === 'bucket-list' && infoExpanded ? 'highlight' : ''">
    <div v-if="entry.message !== undefined" class="blank">
      <span v-text="entry.message"></span>
    </div>
    <div v-else>
      <button v-if="isTrack && !previewMode" class="addbutton entry-icon" @click="toggleAdded">
        <svg v-show="isAdded === true" title="In Bucket" class="bs-icon" style="color: #cd5c5c">
          <use xlink:href="/static/symbols.svg#i-checkmark" />
        </svg>
        <svg v-show="isAdded !== true" title="Add to Bucket" class="bs-icon">
          <use xlink:href="/static/symbols.svg#i-plus" />
        </svg>
      </button>

      <div class="entry-info">
        <svg title="Private" class="bs-icon privateicon" v-if="entry.sharing === 'private'">
          <use xlink:href="/static/symbols.svg#i-lock" />
        </svg>
        <span class="username">{{ entry.user.username }}</span>
        <!-- <span v-if="isRepost" class="reposts">
          <svg title="Reposted {{ entry.numReposts }} time(s)" class="bs-icon" v-for="n in entry.numReposts"><use xlink:href="/static/symbols.svg#i-activity"></svg>
          <!- - <i class="dripicons-retweet" v-for="n in entry.numReposts"></i> - ->
        </span> -->
        <!-- <template v-if="listName === 'bucket-list'">
          <div class="edit-icon" @click="editNote" :class="{ disabled: !isAdded }">
            <svg title="Edit Note" class="bs-icon">
              <use xlink:href="/static/symbols.svg#i-file">
            </svg>
          </div>
          <div class="edit-icon" @click="editTags" :class="{ disabled: !isAdded }">
            <svg title="Edit Tags" class="bs-icon">
              <use xlink:href="/static/symbols.svg#i-star">
            </svg>
          </div>
        </template> -->

        <span v-if="isTrack" class="tracktitle">
          <span v-if="entry.collapsed && listName === 'activities-list'" style="color: #999">({{ entry.playlist_title }})</span>
          <span>{{ entry.title }}</span>
        </span>
        <span v-if="isPlaylist" class="playlisttitle" @click="togglePlaylist">
          <!-- <i class="dripicons-list"></i> -->
          <svg title="View Playlist" class="bs-icon">
            <use xlink:href="/static/symbols.svg#i-menu" />
          </svg>
          {{ entry.title }}
        </span>

        <button class="expandbutton entry-icon" @click="infoExpanded = !infoExpanded">
          <!-- <i v-show="!infoExpanded" class="dripicons-chevron-down"></i>
          <i v-show="infoExpanded" class="dripicons-chevron-up"></i> -->
          <svg v-bind:title="infoExpanded ? 'Hide Info' : 'Show Info'" class="bs-icon">
            <use v-bind:xlink:href="'/static/symbols.svg#i-chevron-' + (infoExpanded ? 'top' : 'bottom')" />
          </svg>
        </button>
      </div>
      <div v-show="infoExpanded" class="description -break-word">
        <span v-if="isPlaylist" style="display: block"><b>{{ entry.track_count }} track{{ entry.track_count > 1 ? 's' : '' }}</b></span>
        <template v-if="listName === 'bucket-list'">
          <hr>
          <i class="block attrtitle">note</i>
          <span v-if="compiledNote" v-html="compiledNote" class="-u-note-text block"></span>
          <a v-if="!readOnly" href="#" @click.prevent="editNote" :class="{ disabled: !isAdded }">edit</a>
          <hr>
          <i class="block attrtitle">tags</i>
          <!-- <span v-if="entry.tags.length" class="block">{{ entry.tags.join(', ') }}</span> -->
          <tag-buttons :tags="tags" :active="true" :clickable="false"></tag-buttons>
          <a v-if="!readOnly" href="#" @click.prevent="editTags" :class="{ disabled: !isAdded }">edit</a>
          <hr>
          
          <button class="expandbutton block" @click="descriptionExpanded = !descriptionExpanded">
            <span>{{ descriptionExpanded ? 'Hide Description' : 'Show Description' }}</span>
            <svg v-bind:title="descriptionExpanded ? 'Hide Description' : 'Show Description'" class="bs-icon">
              <use v-bind:xlink:href="'/static/symbols.svg#i-chevron-' + (descriptionExpanded ? 'top' : 'bottom')" />
            </svg>
          </button>
        </template>
        <div v-show="listName === 'activities-list' || descriptionExpanded">
          <span v-html="compiledDescription" class="block"></span>
          <span class="block">-</span>
          <!-- TODO: add a ? superscript that links to the "Simplify One-Track Playlists" mode in Settings -->
          <span v-if="entry.collapsed && listName === 'activities-list'"><br>collapsed from a one-track playlist</span>
          <br>
          <span class="time" @mouseover="showingTimestamp = true" @mouseout="showingTimestamp = false">
            <span v-show="!showingTimestamp">posted {{ entry.created_at | readableTimeSince }}</span>
            <span v-show="showingTimestamp">{{ entry.created_at }}</span>
          </span>
          <div class="track-link">
            <a :href="entry.permalink_url" target="_blank">
              <!-- <i class="dripicons-arrow-thin-right"></i> -->
              <svg title="View on SoundCloud" class="bs-icon">
                <use xlink:href="/static/symbols.svg#i-arrow-right" />
              </svg>
            </a>
          </div>
        </div>
        <hr v-show="listName === 'bucket-list'">
      </div>
      <audio-player v-if="isTrack" ref="player" class="audio-player" :src="streamSrc" @ended="playerEnded" @play="onPlay" @pause="onPause"></audio-player>
      <playlist v-if="isPlaylist && playlistExpanded" ref="playlist" class="playlist" :uri="entry.tracks_uri.split('https://api.soundcloud.com')[1]" :id="entry.id" @ended="playerEnded"></playlist>
    </div>
  </li>
</template>

<script>
/* eslint-disable no-console, no-underscore-dangle */

import Playlist from './components/Playlist';
import AudioPlayer from './components/AudioPlayer';
import TagButtons from '../../../misc/TagButtons';

export default {
  name: 'Entry',
  props: ['listName', 'playlistId', 'entry', 'type', 'defaultAdded', 'mode', 'readOnly'],
  data() {
    this.entry.component = this;

    this.entry.uuid = global.uuid();

    return {
      streamSrc: '',

      // format of `type` property used to be: 'track', 'playlist', 'track-repost', 'playlist-repost'
      // but now (2023-05-21) it's 'track', 'playlist', 'track:repost', 'playlist:repost'
      // in case it changes back, now using a more flexible check
      isTrack: this.type.startsWith('track'),
      isPlaylist: this.type.startsWith('playlist'),
      isRepost: this.type.endsWith('repost'),

      isAdded: this.defaultAdded != null ? this.defaultAdded : null,

      playing: false,

      playlistExpanded: false,
      infoExpanded: false,
      descriptionExpanded: false,

      showingTimestamp: false,

      lastPlayed: false,

      hidden: false,

      note: this.entry.note || '',
      tags: this.entry.tags || [],
    };
  },
  computed: {
    compiledDescription() {
      let desc = this.entry.description;
      ['getText', 'autolink', 'convertLineBreaks'].forEach((fn) => {
        desc = global.filters[fn](desc);
      });
      return desc;
    },
    compiledNote() {
      let note = this.note;
      if (note) {
        ['autolink', 'convertLineBreaks'].forEach((fn) => {
          note = global.filters[fn](note);
        });
      }
      return note;
    },
    previewMode() {
      return this.mode === 'preview';
    },
    // isNonEmpty() {
    //   if (this.isTrack) return !!this.entry;
    //   if (this.isPlaylist) return this.$refs.playlist.entries.length > 0;
    //   return false;
    // },
  },
  created() {
    if (this.isAdded == null) this.updateAdded();

    const bindings = {
      'autoplay': this.autoplay,
      'entry:updateAdded': this.updateAdded,
    };
    if (this.listName === 'bucket-list') {
      bindings['entry:update-note'] = this.updateNoteFromBucket;
      bindings['entry:update-tags'] = this.updateTagsFromBucket;
    }
    bus.bindAll(this, bindings);

    SC.get(`/tracks/${this.entry.id}/streams`).then((response) => {
      this.streamSrc = response.http_mp3_128_url;
    });
  },
  beforeDestroy() {
    bus.unbindAll(this);
  },
  methods: {
    // if data is provided, update isAdded with it;
    // otherwise, let's fetch it from the bucket
    async updateAdded({ entry, newStatus } = {}) {
      if (entry && newStatus != null) {
        if (this.entry.id === entry.id) {
          this.isAdded = newStatus;
        }
      } else {
        try {
          this.isAdded = await global.bucket.getIsAdded(this.entry.id);
        } catch (err) {
          global.throwErr(err, 'Could not check for track in bucket');
        }
      }
    },
    toggleAdded() {
      const newIsAdded = !this.isAdded;
      const method = newIsAdded ? 'push' : 'remove';
      global.bucket[method](this.entry.id)
        .then((response) => {
          if (process.env.NODE_ENV === 'development') console.info('bucket response:', response);

          // if the track has been restored from trash, update this entry
          // so that when onIsAddedChanged is called, BucketList will add this entry
          // ?? TODO: this Entry will remove `entry.note` and `entry.tags` when BucketList has it..?
          const track = response.track;
          if (track) {
            if (track.note) this.entry.note = track.note;
            if (track.tags.length) this.entry.tags = track.tags;
          }

          this.isAdded = newIsAdded;
        })
        .catch((err) => global.throwErr(err, 'Could not move track to/from bucket'));
    },
    // used by nav bar (..?)
    togglePlaying(event) {
      if (event) event.stopPropagation();

      if (this.$refs.player == null) return;
      if (this.playing) this.$refs.player.$el.pause();
      else this.$refs.player.$el.play();
    },

    onPlay() {
      if (this.isTrack && !this.previewMode) bus.$emit('entry:play', this);
      this.playing = true;
    },
    onPause() {
      if (this.isTrack && !this.previewMode) bus.$emit('entry:pause', this);
      this.playing = false;
    },
    playerEnded() {
      this.$emit('ended', this.entry.uuid);
    },
    autoplay(entryId) {
      if (this.entry.uuid !== entryId) return;

      if (this.isTrack) this.$refs.player.autoplay();
      else if (this.isPlaylist) this.$refs.playlist.autoplay();
    },

    togglePlaylist() {
      if (this.playlistExpanded) {
        bus.$emit('entry:playlist-close', this.entry.id);
        this.playlistExpanded = false;
      } else {
        this.playlistExpanded = true;
      }
    },
    editNote() {
      bus.$emit('entry:edit-note', this.entry);
    },
    editTags() {
      bus.$emit('entry:edit-tags', this.entry);
    },
    updateNoteFromBucket(track) {
      if (this.listName === 'bucket-list' && this.entry.id === track.trackId) {
        this.note = this.entry.note = track.note;
      }
    },
    updateTagsFromBucket(track) {
      if (this.listName === 'bucket-list' && this.entry.id === track.trackId) {
        this.tags = this.entry.tags = track.tags;
      }
    },
  },
  watch: {
    isAdded: function onIsAddedChanged(newStatus, oldStatus) {
      bus.$emit('entry:updateAdded',
        { entry: this.entry, newStatus, listName: this.listName, oldStatus });
      return;
    },
    infoExpanded: function onInfoExpandedChanged(newStatus) {
      this.$emit('infoExpandedChanged', newStatus);
    },
  },
  // mounted() {
    // if (this.collapseOneTrackPlaylist) {
    //   console.log(this.entry.kind);
    //   const playlistTitle = this.entry.title;
    //   this.entry = (await SC.get(this.entry.tracks_uri.replace(global.apiBaseUrlRgx, ''), {
    //     limit: 1,
    //   }))[0];
    //   console.log(this.entry.kind);

    //   this.entry.playlist_title = playlistTitle;
    //   this.type = 'track';
    //   this.isPlaylist = false;
    //   this.isTrack = true;

    //   this.entry.component = this;

    //   this.entry.uuid = uuid();
    // }

    // don't check whether the entry is in bucket if a value for isAdded is set
    // (so that the requests don't need to be made for entries in the bucket list)
  // },
  components: { Playlist, AudioPlayer, TagButtons },
};
</script>

<style lang="scss" scoped>
li {
  font-size: 100%;
  position: relative;

  &.highlight {
    border-left: 1px solid #eee;
    padding-left: 1em;
    margin-left: calc(-1em - 1px) !important; // need to override the .track-list > li { margin } setting 
  }

  &.previewmode {
    background-color: rgba(255, 255, 255, 0.5);
    &.descexpanded {
      background-color: rgba(255, 255, 255, 0.75);
    }
  }

  > div {
    position: relative;

    .edit-icon {
      display: inline-block;
      .bs-icon {
        cursor: pointer;
      }
    }
    
    .addbutton {
      position: absolute;
      right: 1.66em;
      padding-left: 0.33em;
      z-index: 1;
      .bs-icon {
        cursor: pointer;
        color: #777;

        /*&.is-added {
          color: white;
          background-color: #333;
          border-radius: 100%;
        }*/
      }
    }

    &.blank {
      padding: 0.7em 0 1em 0;
      color: #777;
    }

    // .side-item {
    //   font-size: 2.5em;
    //   position: absolute;
    //   margin-left: -1.2em;
    //   margin-top: 0.033em;
    // }

    .entry-info {
      left: 1px;
      position: relative;

      .username {
        display: inline-block;
        color: #777;
        font-weight: 600;
      }

      .reposts {
        font-size: 0.85em;
        position: absolute;
      }

      .tracktitle, .playlisttitle {
        display: block;
        color: #333;
      }

      .playlisttitle {
        cursor: pointer;
      }

      .expandbutton {
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 0.33em;
      }
    }
  }
}

.description {
  position: relative;

  .track-link {
    position: absolute;
    bottom: 0.33em;
    right: 0.66em;
    a {
      text-decoration: none;
    }
    svg {
      cursor: pointer;
      color: #b6b6b6;

      &:hover { color: #333; }
    }
  }

  .attrtitle {
    text-transform: uppercase;
  }

  // in bucket entries
  .expandbutton {
    padding: 0.5em;
  }
}

.entry-icon {
  margin-top: -0.4em;
  padding: 0.4em 0;
}
</style>