<template>
  <div id="tags-explorer">
    <div class="control-container">
      <span class="stat">
      {{ app.stats.untaggedTracksCount }} untagged track{{ app.stats.untaggedTracksCount === 1 ? '' : 's' }}{{ app.stats.untaggedTracksCount === 0 ? '' : ' hidden' }}
      </span>
      <span class="stat">
      {{ app.stats.unusedTagsCount }} unused tag{{ app.stats.unusedTagsCount === 1 ? '' : 's' }}{{ app.stats.unusedTagsCount === 0 ? '' : ' hidden' }}
      </span>
      <span class="divider"></span>
      <span class="control" @click="andOperation = !andOperation">
        <!-- <input id="and-op" type="checkbox" v-model="andOperation">
        <label for="and-op">Use <b>{{ andOperation ? 'AND' : 'OR' }}</b> Operation</label> -->
        Filter: Use <b>{{ andOperation ? 'AND' : 'OR' }}</b> Logic
      </span>
    </div>

    <div class="graph" ref="graph">
      <div v-if="app.inspectMode.active" class="overlay-strip">
        <span class="label"><b>showing tracks with {{ selectedLanguage }}:</b> {{ selectedTags.join(', ') }}</span>
        <span @click="exitInspectMode()" class="exit btn">clear filter</span>
      </div>
      <div class="entry-container" v-if="selectedEntry || isLoadingSelectedEntry" :class="infoExpanded ? 'infoexpanded' : ''">
        <span v-show="isLoadingSelectedEntry">Loading preview...</span>
        <template v-if="selectedEntry">
          <entry :entry="selectedEntry" type="track" mode="preview" list-name="activities-list" @infoExpandedChanged="infoExpanded = $event"></entry>
          <span @click="selectedEntry = null" class="exit btn">close</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console, no-param-reassign */
import Entry from './Entry';

import * as tagsExplorer from './tagsExplorer';

//

export default {
  name: 'TagsExplorerApp',
  data() {
    return {
      app: tagsExplorer,
      andOperation: false,
      selectedTags: [],
      selectedEntry: null,
      isLoadingSelectedEntry: false,
      infoExpanded: false,
    };
  },
  mounted() {
    this.init();
  },
  created() {
    bus.bindAll(this, {
      'tagsExplorer:selected-tags': this.updateSelectedTags,
      'tagsExplorer:display-entry': this.displayEntry,
    });
  },
  beforeDestroy() {
    bus.unbindAll(this);
  },
  // beforeDestroy() {
  //   this.app.s.killRenderer('0');
  // },
  methods: {
    init() {
      Promise.all([global.bucket.load, global.bucket.loadTags])
        .then(() => {
          const tracks = global.bucket.allTracks.filter((track) => (!track.removed));
          const tags = global.bucket.tags;

          // BEGIN TEMP
          // const tags =
          //   ['ambient', 'electronic', 'rock', 'ridiculous', 'hmmm', 'blues', 'yipiee', 'narnia'];
          // const tracks = [];
          // let certainTags;
          // let certainTag;
          // const each = (el, j) => {
          //   do {
          //     certainTag = tags[Math.random() * tags.length | 0];
          //   } while (certainTags.indexOf(certainTag) !== -1);
          //   certainTags[j] = certainTag;
          // };
          // for (let i = 0; i < 50; i++) {
          //   certainTags = new Array(Math.random() * 4 | 0).fill(true);
          //   certainTags.forEach(each);
          //   tracks.push({
          //     trackId: (Math.random() * 1000000) | 0,
          //     tags: certainTags,
          //   });
          // }
          // END TEMP

          this.app.setup({
            bucket: { tracks, tags },
            container: this.$refs.graph,
            renderOptions: {
              labelXPadding: 4,
              labelYPadding: 4.5,
            },
          });

          global.tagsExplorer = this.app;
        }).catch(global.throwErr);

      // global.tagsExplorer = this.app;
    },
    updateSelectedTags(selectedTags) {
      if (typeof selectedTags === 'object') this.selectedTags = selectedTags;
    },
    exitInspectMode() {
      this.app.inspectMode.active = false;
    },
    displayEntry(entry) {
      if (entry && entry[0]) {
        this.isLoadingSelectedEntry = true;
        this.selectedEntry = null; // to refresh the player
        setTimeout(() => {
          this.selectedEntry = entry[0];
          this.isLoadingSelectedEntry = false;
        }, 500);
      }
    },
  },
  computed: {
    selectedLanguage() {
      if (this.selectedTags.length === 1) return 'this tag';
      if (!this.andOperation) return 'any of these tags';
      if (this.selectedTags.length === 2) return 'both of these tags';
      return 'all these tags';
    },
  },
  watch: {
    andOperation(newValue) {
      this.app.inspectMode.andOperation = newValue;
    },
  },
  components: { Entry },
};
</script>

<style lang="scss" scoped>
#tags-explorer {
  // margin-top: 1em;
  user-select: none;
  position: relative;
}

.btn {
  padding: 0.125em 0.25em;
  margin: 0.25em 0.25em 0.25em 0;
  cursor: pointer;
  border: 1px solid currentColor;
  border-radius: 0.25em;
}

.control-container {
  line-height: 2.6em;
  padding: 0.5em 0 1.5em 0;
  .stat, .control {
    padding: 0.5em;
    margin: 0.25em;
    &:first-child {
      margin-left: 0;
    }
  }
  .stat {
    border: 1px solid currentColor;
    border-radius: 0.25em;
  }
  .control {
    $green: darken(#bada55, 15%);
    // background: #eee;
    color: #777;
    b {
      color: $green;
    }
    cursor: pointer;
    border: 1px solid $green;
    border-radius: 0.5em;
  }
  .divider {
    margin: 0 0.25em 0 0.5em;
    width: 0.15em;
    background-color: #ccc;
    display: inline-block;
    height: 2em;
    position: relative;
    top: 0.6em;
  }
}

// @media screen and (max-width: 35em) {
//   .stats-container {
//     padding: 0.5em 0 1.5em 0;
//   }
// }

.graph {
  position: relative;
  width: 100%;
  height: calc(100vh - 13.2em);
  min-height: 20em;
  background: #fff;
  border: 1px solid #333;
}

.overlay-strip {
  position: absolute;
  width: 100%;
  padding: 0.5em;
  z-index: 10;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid currentColor;
  // background-color: rgba(186, 218, 85, 0.35);
  // color: #cd5c5c;
  .label {
    float: left;
    margin: 0.4em;
  }
  .exit {
    color: #5c5ccd;
    pointer-events: initial;
    float: right;
    margin-left: 0.25em;
  }
}

.entry-container {
  padding: 1em;
  border: 1px solid currentColor;
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  &.infoexpanded {
    background-color: rgba(255, 255, 255, 0.9);
  }
  &:not(.infoexpanded) {
    max-width: 35%;

    @media screen and (max-width: 45.25em) {
      max-width: 70%;
    }
  }
  max-height: 100%;
  overflow-y: auto;

  .exit {
    float: right;
    margin-top: 0.5em;
  }

  > li {
    list-style: none;
  }
}
</style>