<template>
  <header :class="$parent.showingBucket ? 'accent' : ''">
    <nav class="navigation-bar">
      <div class="navitem currently-playing" :class="{ stale: stale }" v-if="lastPlayed" @click="scrollToLastPlayed" style="cursor: pointer">
        <div class="controls">
          <div class="control icon" @click="lastPlayed.togglePlaying">
            <svg v-show="lastPlayed.playing" title="Pause">
              <use xlink:href="/static/symbols.svg#plyr-pause" />
            </svg>
            <svg v-show="!lastPlayed.playing" title="Play">
              <use xlink:href="/static/symbols.svg#plyr-play" />
            </svg>
          </div>
        </div>
        <span class="user" v-text="lastPlayed.entry.user.username"></span>
        <span class="title" v-text="lastPlayed.entry.title"></span>
      </div>
      <div class="navitem nav-menu">
        <button v-show="$parent.showingBucket" @click="shareBucket">
          <svg title="Share Bucket" class="bs-icon">
            <use xlink:href="/static/symbols.svg#i-link" />
          </svg>
        </button>
        <button @click="refreshList">
          <!-- <span>Refresh</span> -->
          <svg title="Reload" class="bs-icon">
            <use xlink:href="/static/symbols.svg#i-reload" />
          </svg>
        </button>
        <!-- <button @click="$parent.showingBucket = !$parent.showingBucket">
          <svg title="View Bucket" class="bs-icon">
            <use xlink:href="/static/symbols.svg#i-archive">
          </svg>
        </button> -->

        <button v-show="!$parent.showingBucket" @click="optionsExpanded = !optionsExpanded" class="-last-child">
          <svg v-show="!optionsExpanded" title="Menu" class="bs-icon -fill">
            <use xlink:href="/static/symbols.svg#i-caret-bottom" />
          </svg>
          <svg v-show="optionsExpanded" title="Close Menu" class="bs-icon -fill">
            <use xlink:href="/static/symbols.svg#i-caret-top" />
          </svg>
        </button>
        <button v-show="$parent.showingBucket" @click="closeBucket" class="-last-child">
          <svg title="Close" class="bs-icon">
            <use xlink:href="/static/symbols.svg#i-close" />
          </svg>
        </button>
      </div>
    </nav>
    <options-bar v-show="optionsExpanded"></options-bar>
    <!-- show the bottom of the drawer if bucket is open; it will open internally -->
    <tags-manager v-show="$parent.showingBucket"></tags-manager>
  </header>
</template>

<script>
/* eslint-disable no-console */

import OptionsBar from './components/OptionsBar';
import TagsManager from './components/TagsManager';

export default {
  name: 'NavigationBar',
  props: ['lastPlayed'],
  data() {
    return {
      optionsExpanded: false,
      // tagsManagerExpanded: false,
      stale: false,
    };
  },
  created() {
    bus.bindAll(this, {
      'nav:last-played-update-stale': this.lastPlayedUpdateStale,
    });
  },
  methods: {
    refreshList() {
      // have Raudio broadcast the event
      // only the list currently being viewed will refresh
      bus.$emit('refresh');
    },
    closeBucket() {
      bus.$emit('toggleBucket');
    },
    shareBucket() {
      bus.$emit('shareBucket');
    },
    scroll(el = this.lastPlayed.$el) {
      const navBarHeight = this.$el.getBoundingClientRect().height;
      global.scrollToElement(el, 500, -navBarHeight - 16);
    },
    scrollToLastPlayed() {
      if (this.lastPlayed.listName === this.$parent.showingListName) {
        this.scroll();
      } else {
        this.$parent.showingBucket = !this.$parent.showingBucket;
        // wait until vue makes the change
        this.$nextTick(this.scroll);
      }
    },
    lastPlayedUpdateStale(newVal) {
      if (typeof newVal !== 'boolean') return;
      this.stale = newVal;
    },
  },
  components: { OptionsBar, TagsManager },
};
</script>

<style lang="scss">
// navigation-bar //
header {
  background-color: #333;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3.42em;
  opacity: .96;
  z-index: 11;

  &.accent {
    background-color: #cd5c5c;

    > nav {
      background-color: #cd5c5c;

      .user {
        color: #333 !important;
      }

      // &.options-bar .navitem button {
      //   border-color: #333;
      //   color: #333;

      //   &.accent {
      //     border-color: #fff;
      //     color: #fff;
      //   }
      // }
    }
  }
}

nav {
  // width: 65%;
  min-width: 20rem;
  max-width: 37rem;
  margin: auto;

  background-color: #333;
  color: #fff;
  padding: 0.5em;
  font-size: 100%;
  line-height: 1.42em;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 0;
  z-index: 11;

  &.navigation-bar {
    z-index: 15;
  }

  .navitem {
    position: relative;
  }

  .navitem.separator::before {
    display: block;
    height: 1em;
    width: 1em;
    font-size: 1.4em;
    color: #777;
    content: "|";
    margin: 0 0 0 0.5em;
  }
}

.navitem.currently-playing {
  word-wrap: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.5em;
  margin-left: -0.5em;

  .user {
    color: #888;
    font-weight: 600;
  }

  .title {
    color: #fff;
  }

  &.stale {
    color: #666;
    cursor: default !important;
    span, .controls, .controls div {
      color: #666 !important;
      cursor: default !important;
    }
  }

  .controls {
    display: inline-block;
    font-size: 1em;
    position: relative;

    > .control.icon {
      cursor: pointer;
      display: inline-block;
      padding: 0.5em;
      margin-left: -0.5em;
      
      > svg {
        width: 1em;
        height: 1em;
        top: 0.1em;
        position: relative;
      }
    }
  }
}

.navitem.nav-menu {
  margin-left: auto;

  > button {
      padding: 0.5em 0 0.5em 0.5em;

      &:first-child {
        margin-left: 1em;
        padding-left: 0.5em;
      }

      &.-last-child {
        padding-left: 0.5em;
        padding-right: 0.5em;
      }
    }
}

.navitem {
  white-space: nowrap;

  button {
    padding: 0 3px;

    &.border {
      border: 1px solid #fff;
    }
    &.accent {
      border-color: #cd5c5c;
      color: #cd5c5c;
    }
  }
}

.menu {
  width: 1em;
  height: 1em;

  > svg {
    font-size: 0.9em;
  }
}
</style>