<template>
  <div id="raudio-app" v-if="oauth_token">
    <div id="main" v-show="!showingExplorer">
    	<nav is="navigation-bar" :last-played="lastPlayedEntryComponent" ref="nav"></nav>
      <main class="app">
        <activities-list v-show="!showingBucket"></activities-list>
        <bucket-list v-show="showingBucket"></bucket-list>
      </main>
      <edit-bucket-note></edit-bucket-note>
      <edit-bucket-tags></edit-bucket-tags>
      <create-edit-tag></create-edit-tag>
      <share-bucket></share-bucket>
    </div>
    <div id="explorer" v-if="showingExplorer">
      <tags-explorer></tags-explorer>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console, camelcase, no-underscore-dangle */

// attaches SC to global
require('soundcloud');

global.plyr = require('plyr');
global.plyrSettings = {
  controls: ['play', 'progress'],
  iconUrl: '/static/symbols.svg',
  keyboardShortcuts: { focused: false, global: false },
};

import notie from 'notie';
/* temp; remove when create global methods that use it */ global.notie = notie;
global.throwErr = function errorCatchHandler(err, msg, stay = false) {
  console.error('Caught error: ', err);
  notie.alert({
    type: 'error',
    text: msg ? `Error: ${msg}` : 'Uncaught Error',
    stay,
  });
};

// TODO: confirm for settings (see Settings.vue)

import swal from 'sweetalert';
global.swal = swal;
// unnecessary for now; was intended for an 'Are you sure you want to
// remove this track from your bucket?', until a trash is implemented,
// but this dialog would be intrusive/bothersome anyway
// global.confirmDialog = function confirmDialog({
//   title, text, type = 'warning', confirmButtonText = 'Yes!',
// }, cb) {
//   swal({
//     title, text, type,
//     showCancelButton: true,
//     confirmButtonColor: '#DD6B55',
//     confirmButtonText,
//     closeOnConfirm: true,
//     html: false,
//   }, cb);
// };
//

import Vue from 'vue';
import Entry from './components/components/Entry';
Vue.component('entry', Entry);

// // MAIN APP
import NavigationBar from './components/NavigationBar';
// Lists
import ActivitiesList from './components/ActivitiesList';
import BucketList from './components/BucketList';
// Modals
import EditBucketNote from './components/EditBucketNote';
import EditBucketTags from './components/EditBucketTags';
import CreateEditTag from './components/CreateEditTag';
import ShareBucket from './components/components/ShareBucket';

// // EXPLORER
import TagsExplorer from './components/TagsExplorer';

//
const bucketCreator = require('../bucket').default;
//

export default {
  name: 'RaudioApp',
  data() {
    return {
      oauth_token: undefined,
      lastPlayedEntryComponent: undefined,
      showingListName: 'activities-list',
      showingAppName: 'main',
    };
  },
  computed: {
    showingBucket: {
      get() {
        return this.showingListName === 'bucket-list';
      },
      set(val) {
        this.showingListName = val ? 'bucket-list' : 'activities-list';
      },
    },
    showingExplorer: {
      get() {
        return this.showingAppName === 'explorer';
      },
      set(val) {
        this.showingAppName = val ? 'explorer' : 'main';
      },
    },
  },
  beforeEnter() {
    const oauthTokenInStorage = localStorage.getItem(global.SC_AUTH_TOKEN_STORAGE_KEY);
    if (oauthTokenInStorage) return true;
    return false;
  },
  created() {
    // init SC
    this.oauth_token = localStorage.getItem(global.SC_AUTH_TOKEN_STORAGE_KEY);

    if (!this.oauth_token) {
      global.router.replace('/auth');
      return;
    }

    global.oauth_token = this.oauth_token;

    SC.initialize({
      client_id: global.SC_CLIENT_ID,
      oauth_token: global.oauth_token,
      redirect_uri: global.SC_REDIRECT_URI,
    });

    // init bucket
    global.bucket = bucketCreator(global.oauth_token);
    global.bucket.loadBucket();
    global.bucket.loadTagNames();

    this.bindHandlers();

    bus.bindAll(this, {
      'refresh': this.refreshOpenList,
      'toggleBucket': this.toggleBucket,
      'open-explorer': this.openExplorer,
      'close-explorer': this.closeExplorer,
      'entry:play': this.onEntryPlay,
      'entry:pause': this.onEntryPause,
      'entry:playlist-close': this.onPlaylistClose,
      'raudio:update-page-title': this.onUpdatePageTitle,
    });
  },
  beforeDestroy() {
    bus.unbindAll(this);
  },
  methods: {
    bindHandlers() {
      window.document.body.onkeydown = (e) => {
        if (!['TEXTAREA', 'INPUT'].includes(document.activeElement.tagName)
          && e.keyCode === 32 && this.$refs.nav.lastPlayed) {
          this.togglePlaying();
          e.preventDefault();
          return false;
        }
        return true;
      };
      // window.document.body.onkeyup = (e) => {
      //   if (e.keyCode === 32 && this.$refs.nav.lastPlayed) {
      //     this.togglePlaying();
      //     e.preventDefault();
      //     return false;
      //   }
      //   return true;
      // };
    },
    refreshOpenList() {
      if (this.lastPlayedEntryComponent
        && this.lastPlayedEntryComponent.listName === this.showingListName) {
        bus.$emit('nav:last-played-update-stale', true);
      }
      bus.$emit(`${this.showingListName}:refresh`);
    },
    onPlaylistClose(playlistId) {
      if (!this.lastPlayedEntryComponent) return;
      if (this.lastPlayedEntryComponent.playlistId === playlistId) {
        bus.$emit('nav:last-played-update-stale', true);
      }
    },
    onEntryPlay(entry) {
      bus.$emit('nav:last-played-update-stale', false);
      this.lastPlayedEntryComponent = entry;
      const radio = String.fromCodePoint(55357, 56571);
      document.title = `${radio} | ${entry.entry.title} by ${entry.entry.user.username}`;
    },
    onEntryPause(entry) {
      document.title = `raudio | ${entry.entry.title} by ${entry.entry.user.username}`;
    },
    toggleBucket() {
      this.showingBucket = !this.showingBucket;
    },
    togglePlaying() {
      this.$refs.nav.lastPlayed.togglePlaying();
    },
    openExplorer() {
      this.showingExplorer = true;
    },
    closeExplorer() {
      this.showingBucket = true;
      this.showingExplorer = false;
    },
  },
  components: {
    NavigationBar,
    ActivitiesList, BucketList,
    EditBucketNote, EditBucketTags, CreateEditTag, ShareBucket,
    TagsExplorer,
  },
};
</script>

<style lang="scss">
@import url('../../node_modules/plyr/dist/plyr.css');
@import url('../../node_modules/text-spinners/spinners.css');
@import url('../../node_modules/notie/dist/notie.min.css');
@import url('../../node_modules/sweetalert/dist/sweetalert.css');

.notie-container {
  z-index: 13;
  font-size: 100%;
  // max-width: 52rem;
  // margin-top: calc(3.42em - 1px);
  opacity: 0.7;
  box-shadow: none;
  &.notie-background-error {
    background-color: #f00;
  }

  .notie-textbox-inner {
    font-size: 1.0625rem;
    font-family: "Varela Round", sans-serif;
  }
}

// app layout //
#raudio-app {
  position: relative;
  width: 100%;
  font-size: 1rem;
  font-family: "Varela Round", sans-serif;
  
  .app {
    margin: auto;
    margin-top: 3.42em;
    padding: 1em;
  }
}

ul.-no-bullets {
  list-style-type: none;
}

.-fill {
  fill: currentColor;
}

.-break-all {
  word-break: break-all;
}

.-break-word {
  word-break: break-word;
}

button {
  border: 0;
}

// uncomment when tweaking button hitboxes
// button {
//   background-color: rgba(255,0,0,0.6);
// }

/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  ::-webkit-scrollbar {
      width: $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

  body {
    overflow-y: scroll; // force y scrollbar
    // For Internet Explorer
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

// TODO: make scrollbar have cursor: pointer
@include scrollbars(0.75rem, rgba(136,136,136,0.7), rgba(200,200,200,0.7));
</style>