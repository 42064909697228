<template>
  <modal v-if="showing" primaryButtonName="Close" accentColor="#cd5c5c" @exit="showing = false">
    <h3 slot="header">Edit Tags</h3>
    <div slot="body" class="field-group">
      <label class="block"><b>{{ editingEntry.user.username }}</b><br>{{ editingEntry.title }}</label>
      <br>
      <tag-buttons v-if="tags && tags.length" caption="click tags to remove" :tags="tags" :active="true" :force-scrollbar="true" @clicked="removeTag($event.i)"></tag-buttons>
      <blank-state v-else>no tags</blank-state>
      <br>
      <div class="add-tags">
        <tag-buttons title="Add tags:" caption="click tags to add" :tags="otherTags" :active="false" :force-scrollbar="true" :search-enabled="true" @clicked="addTag($event.tagName)"></tag-buttons>
      </div>
    </div>
  </modal>
</template>

<script>
/* eslint-disable no-console */
import Modal from '../../misc/Modal';
import TagButtons from '../../misc/TagButtons';
import BlankState from '../../misc/BlankState';

export default {
  name: 'EditBucketTags',
  data() {
    return {
      editingEntry: null,
      tags: [],
      showing: false,
      allTags: [],
    };
  },
  created() {
    bus.bindAll(this, {
      'entry:edit-tags': this.openModal,
      'bucket:tags-update': this.updateTags,
      'entry:update-tags': this.updateEntryTags,
    });
  },
  beforeDestroy() {
    bus.unbindAll(this);
  },
  methods: {
    openModal(entry) {
      this.editingEntry = entry;
      this.tags = (entry.tags || []).slice(0);
      this.showing = true;
    },
    addTag(tagName) {
      global.bucket.addTagToTrack(this.editingEntry.id, tagName);
    },
    removeTag(i) {
      global.bucket.removeTagFromTrack(this.editingEntry.id, this.tags[i]);
    },
    updateTags(tags) {
      this.allTags = tags;
    },
    updateEntryTags(track) {
      if (this.editingEntry.id === track.trackId) {
        this.tags = track.tags;
      }
    },
  },
  computed: {
    otherTags() {
      // return all tags which aren't on the track
      return this.allTags.filter((tagName) => !this.tags.includes(tagName));
    },
  },
  components: { Modal, TagButtons, BlankState },
};

</script>

<style lang="scss">
.add-tags {
  border-top: 1px solid #000;
  padding-top: 0.7em;
}
</style>