<template>
  <div id="raudio-app" class="container error-container">
    <div>
      <h1>
        <img class="img" src="/static/images/bucket-empty.svg" /> Page not found
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};

</script>

<style lang="scss">
html, body, #app, #raudio-app {
  height: 100%;
}
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;

  .img {
    position: relative;
    height: 1em;
    margin-right: 0.1em;
    top: 0.15em;
  }

  h1 {
    font-family: "Varela Round", sans-serif;
    font-size: 4em;
    margin-bottom: 3em;
    color: #fff;
  }
}
</style>