<template>
  <modal v-if="showing" primary-button-name="Save" secondary-button-name="Cancel" accent-color="#cd5c5c" @success="updatePassword" @exit="showing = false">
    <h3 slot="header">Sharing Settings</h3>
    <div slot="body">
      <div v-show="isHidden && !settingPasswordFromHidden">
        Bucket is hidden <button class="button" @click="settingPasswordFromHidden = true; $refs.passwordInput.focus()">Unhide and set a password</button>
      </div>
      <div v-show="!isHidden">
        <button class="button" @click="hideBucket">Hide Bucket</button>
      </div>

      <div class="field-group" :class="{ disabled: (isHidden && !settingPasswordFromHidden) }">
        <label class="block" for="password">Password (leave blank for public)</label>
        <input type="text" class="field" id="password" v-model="password" ref="passwordInput" placeholder="no password" />
      </div>
      <div v-show="!isHidden" class="field-group">
        <label for="url">Anybody can view this bucket at:</label>
        <input type="text" class="field" id="url" :value="bucketUrl" ref="bucketUrlInput" @focus="$refs.bucketUrlInput.select()" @mouseup="$event.preventDefault()" />
      </div>
    </div>
  </modal>
</template>

<script>
/* eslint-disable no-console */
import Modal from '../../../misc/Modal';

export default {
  name: 'ShareBucket',
  data() {
    return {
      showing: false,
      isHidden: null,
      settingPasswordFromHidden: false,
      userId: '',
      password: '',
    };
  },
  created() {
    bus.bindAll(this, {
      'shareBucket': this.openModal,
    });
  },
  beforeDestroy() {
    bus.unbindAll(this);
  },
  computed: {
    bucketUrl() {
      return `${window.location.protocol}//${window.location.host}/bucket/`
        + `${this.userId}${this.password ? `-${this.password}` : ''}`;
    },
  },
  // watch: {
  //   isHidden(newIsHidden) {
  //     if (newIsHidden === true) {
  //       this.password = '[bucket hidden]';
  //     }
  //   },
  // },
  methods: {
    openModal() {
      this.showing = true;
      this.settingPasswordFromHidden = false;
      global.bucket.load.then(() => {
        this.userId = global.bucket.userId;
        this.isHidden = global.bucket.isHidden;
        if (!this.isHidden) {
          this.password = global.bucket.password;
        }
      });
    },
    updatePassword() {
      const invalid = (this.isHidden && !this.settingPasswordFromHidden);
      if (!invalid) {
        global.bucket.updatePassword(this.password);
      }
    },
    async hideBucket() {
      await global.bucket.makeHidden();
      this.isHidden = true;
      this.showing = false;
    },
  },
  components: { Modal },
};

</script>

<style lang="scss">
#url {
  font-size: 0.75em;
  height: 2.25em;
}
.field-group.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>