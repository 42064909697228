import { render, staticRenderFns } from "./PageNotFound.vue?vue&type=template&id=584c4cba&"
import script from "./PageNotFound.vue?vue&type=script&lang=js&"
export * from "./PageNotFound.vue?vue&type=script&lang=js&"
import style0 from "./PageNotFound.vue?vue&type=style&index=0&id=584c4cba&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_vy273aczmdm3lffmyf7zmhkuji/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports