import { render, staticRenderFns } from "./OptionsBar.vue?vue&type=template&id=3f4ae628&scoped=true&"
import script from "./OptionsBar.vue?vue&type=script&lang=js&"
export * from "./OptionsBar.vue?vue&type=script&lang=js&"
import style0 from "./OptionsBar.vue?vue&type=style&index=0&id=3f4ae628&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_vy273aczmdm3lffmyf7zmhkuji/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f4ae628",
  null
  
)

export default component.exports