<template>
  <div>
    <div v-if="!loaded">Loading playlist...</div>
    <ul v-else class="track-list -no-bullets">
      <li is="entry" v-for="entry in entries" :entry="entry" :type="entry.type" :playlist-id="id" list-name="activities-list" @ended="playerEnded"></li>
    </ul>
  </div>
</template>

<script>
/* eslint-disable no-console */
import List from '../../List';
//

export default {
  name: 'Playlist',
  props: ['uri', 'id'],
  mixins: [List],
  methods: {
    // for List
    fetchTracks(uri) {
      return SC.get(uri, {
        limit: 20,
      });
    },
    filterData(activities) {
      return activities
        .map((origin) => Object.assign(origin, { type: 'track' }))
        .filter(({ streamable }) => (streamable));
    },
    autoplay() {
      this.playTrack(this.entries[0].uuid);
    },
  },
};
</script>

<style lang="scss" scoped>
.track-list {
  border-left: 1px solid #eee;

  > li {
    margin-bottom: 0.7em;
  }
}
</style>