import { render, staticRenderFns } from "./TagsExplorerApp.vue?vue&type=template&id=ff310d42&scoped=true&"
import script from "./TagsExplorerApp.vue?vue&type=script&lang=js&"
export * from "./TagsExplorerApp.vue?vue&type=script&lang=js&"
import style0 from "./TagsExplorerApp.vue?vue&type=style&index=0&id=ff310d42&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_vy273aczmdm3lffmyf7zmhkuji/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff310d42",
  null
  
)

export default component.exports