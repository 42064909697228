<template>
  <div class="tag-buttons-wrapper" :class="{ empty: this.tags.length === 0 }">
    <div class="title-block">
      <label for="search-tags">{{ title }}</label>
      <div v-if="searchEnabled" class="search-bar">
        <input id="search-tags" type="text" placeholder="search..." v-model="searchQuery" />
      </div>
    </div>
    <div class="tag-buttons" :class="{ active, light, clickable, forceScrollbar, caption }">
      <template v-if="searchModeActive">
        <template v-for="(tagName, i) in searchResults">
          <button v-if="tags.includes(tagName)" class="tagbutton" @click="buttonClick(tagName, i)">{{ tagName }}</button>
        </template>
        <blank-state v-show="searchResults.length === 0" slim>no tags match</blank-state>
      </template>

      <template v-for="(tagName, i) in tags">
        <button v-show="!searchModeActive" class="tagbutton" @click="buttonClick(tagName, i)">{{ tagName }}</button>
      </template>
    </div>
    <span v-if="caption" class="captiontext">{{ caption }}</span>
  </div>
</template>

<!-- bring each button into TagButton? -->

<script>
import BlankState from './BlankState';

export default {
  name: 'TagButtons',
  props: {
    tags: {
      type: Array,
    },
    caption: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    forceScrollbar: {
      type: Boolean,
      default: false,
    },
    searchEnabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchModeActive: false,
      searchQuery: '',
      searchResults: [],
      searchFunction() {},
    };
  },
  created() {
    if (this.searchEnabled) {
      this.searchFunction = global.bucket.createTagNamesSearchFn(this.tags);
    }
  },
  methods: {
    buttonClick(tagName, index) {
      let i;
      if (this.searchEnabled && this.searchModeActive) {
        i = this.tags.indexOf(tagName);
      } else {
        i = index;
      }
      this.$emit('clicked', { tagName, i });
    },
    search() {
      this.searchResults = this.searchFunction(this.searchQuery);
    },
  },
  watch: {
    tags(newTags) {
      if (this.searchEnabled) {
        // update the search function
        this.searchFunction = global.bucket.createTagNamesSearchFn(newTags);

        if (this.searchModeActive) {
          // and do the query again so that the results update
          this.search();
        }
      }
    },
    searchQuery(query) {
      if (query.trim() === '') {
        this.searchModeActive = false;
      } else {
        this.searchModeActive = true;
        this.search(query.trim());
      }
    },
  },
  components: { BlankState },
};

</script>

<style lang="scss" scoped>
.tag-buttons-wrapper.empty {
  margin-bottom: calc(-0.4em - 0.5rem);
}
.title-block {
  margin-bottom: 0.4em;
  display: flex;
  align-items: stretch;

  > label {
    padding-top: 0;
  }

  .search-bar {
    height: 1.5em;
    flex: 1;
    > input {
      height: 100%;
      width: 100%;
      outline: none;
      border: none;
      border-bottom: 1px solid currentColor;
      color: #000;
    }
  }
}

.tag-buttons {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  display: flex;
  padding-bottom: 0.5rem;

  &.caption {
    padding-bottom: 1.75rem;
  }

  > .tagbutton {
    padding: 0.5em;
    margin: 0.1em 0.5em;
    border: 1px solid #333;
    white-space: pre;

    &:first-child {
      margin-left: 0;
    }
  }

  &.forceScrollbar {
    overflow-x: scroll;
  }

  &:not(.clickable) > .tagbutton {
    cursor: default;
  }

  &.active > .tagbutton {
    background-color: #bada55; // #bacd5b for subdued alternative
    border: none;
  }

  &.light > .tagbutton {
    border-color: #ddd;
  }
}

.captiontext {
  margin-top: -2.25em;
  position: absolute;
  color: rgba(136, 136, 136, 1);
}
</style>