<template>
  <div class="container" id="home">
    <div class="col-11 col-4-l">
      <h1>Welcome to raudio!</h1>
      <p><b>raudio</b>: A SoundCloud client that isolates track posts to their audio.</p>
      <p><router-link to="/auth">Log in with SoundCloud</router-link></p>
      <p>
      Consider raudio an <i>audio oasis</i>; just <b>music</b>&mdash;no cover art, no waveform&mdash;and a <b>bucket</b>, which you can fill with music you collect.
      </p>
      <p>In the future, you will be able to view your bucket as well as export it as a text file, in the format of a list of track names, artists, and respective SoundCloud links.</p>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console, camelcase */

export default {
  created() {
    const oauth_token = localStorage.getItem(global.SC_AUTH_TOKEN_STORAGE_KEY);
    if (oauth_token) {
      this.proceedToApp(oauth_token);
      return false;
    }
    return true;
  },
  methods: {
    proceedToApp(oauth_token) {
      global.router.push('/app', { params: { oauth_token } });
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
  > div {
    margin: auto;
    float: initial;
  }
}
</style>
