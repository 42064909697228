<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :style="style">
          <div class="modal-header">
            <slot name="header">default header</slot>
          </div>
          <div class="modal-body">
            <slot name="body">default body</slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button v-if="secondaryButtonName" class="button" @click="$emit('cancel') && $emit('exit')">
                {{ secondaryButtonName }}
              </button>
              <button v-if="primaryButtonName" class="button" @click="$emit('success') && $emit('exit')" :disabled="disablePrimary">
                {{ primaryButtonName }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: ['primaryButtonName', 'secondaryButtonName', 'disablePrimary', 'accentColor'],
  computed: {
    style() {
      return this.accentColor ? `border-top: 1em solid ${this.accentColor}` : '';
    },
  },
};

</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 13;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  transition: opacity .3s ease;
}

.modal-wrapper {
  width: 100%;
  margin-top: 3.42em; // header height
}

.modal-container {
  max-width: 100%;
  width: 25rem;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  transition: all .3s ease;
  font-family: "Varela Round", sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  font-family: "Varela Round", sans-serif;
}

.modal-footer {
  height: 2.25em;

  button:last-child {
    float: right;
  }
}

// base.css's .disabled styles (for some reason, I cannot apply .disabled to the primary button)
.button[disabled] {
  opacity: .5;
  pointer-events: none;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>