/* eslint-disable no-console, no-underscore-dangle */

export default {
  name: 'global-bus',
  data() {
    return {
      events: {},
    };
  },
  methods: {
    bindAll(scope, bindings) {
      if (scope == null) {
        console.error('scope undefined');
        return;
      }
      const uid = scope._uid;
      this.events[uid] = {};
      Object.keys(bindings).forEach((eventName) => {
        this.events[uid][eventName] = bindings[eventName];
        this.$on(eventName, this.events[uid][eventName]);
      });
      return;
    },
    // eventHandler(scope, eventName, fn) {
    //   return (...args) => {
    //     if (process.env.NODE_ENV === 'development') {
    //       console.log('[caught to handle]', eventName, scope /* , ...args */);
    //     }
    //     fn.apply(scope, args);
    //   };
    // },
    unbindAll(scope) {
      if (scope == null) {
        console.error('scope undefined');
        return;
      }
      const uid = scope._uid;
      const bindings = this.events[uid];
      if (!bindings) return;
      Object.keys(bindings).forEach((eventName) => {
        const handler = this.events[uid][eventName];
        this.$off(eventName, handler);
      });
      return;
    },
  },
};
