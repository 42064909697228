<template>
  <modal v-if="showing" primaryButtonName="Save" secondaryButtonName="Cancel" accentColor="#cd5c5c" @success="onEditNoteSuccess" @exit="showing = false">
    <h3 slot="header">Edit Note</h3>
    <div slot="body" class="field-group">
      <label class="block" for="note"><b>{{ editingEntry.user.username }}</b><br>{{ editingEntry.title }}</label>
      <textarea name="note" class="field -u-note-text" id="note" cols="30" rows="10" maxlength="600" v-model="lastNote" placeholder="Write note here..."></textarea>
      <div v-show="lastNote.length > 500">{{ 600 - lastNote.length }} characters remaining</div>
    </div>
  </modal>
</template>

<script>
/* eslint-disable no-console */
import Modal from '../../misc/Modal';

export default {
  name: 'EditBucketNote',
  data() {
    return {
      editingEntry: null,
      lastNote: '',
      showing: false,
    };
  },
  created() {
    bus.bindAll(this, {
      'entry:edit-note': this.openModal,
      'entry:update-note': this.updateEntryNote,
    });
  },
  beforeDestroy() {
    bus.unbindAll(this);
  },
  methods: {
    openModal(entry) {
      this.editingEntry = entry;
      this.lastNote = entry.note || '';
      this.showing = true;
    },
    onEditNoteSuccess() {
      global.bucket.editNote(this.editingEntry.id, this.lastNote);
    },
    updateEntryNote(track) {
      if (this.editingEntry.id === track.trackId) {
        this.note = track.note;
      }
    },
  },
  components: { Modal },
};

</script>

<style lang="scss">
#note {
  resize: none;
  line-height: 1.5em;
}
</style>