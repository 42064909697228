<template>
  <div :class="{ slim }">
    <span>
      <slot>There is no content</slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BlankState',
  props: {
    slim: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style lang="scss" scoped>
div {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 1.5em;
  text-align: center;
  display: block;
  > span {
    font-family: "Varela Round", sans-serif;
    position: relative;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    max-width: 90%;
    color: #555;
  }
}

div.slim {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
</style>