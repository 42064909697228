<template>
<!-- :class="{ show: open }" -->
  <nav class="tags-manager" @click="$parent.tagsManagerExpanded = false">
    <div v-show="show" class="manager">
      <div class="explorerbutton" @click="openExplorer">
        <svg title="Open Tags Explorer" class="bs-icon">
          <use xlink:href="/static/symbols.svg#i-search" />
        </svg>
      </div>
      <tag-buttons :tags="['+'].concat(tags)" :active="false" :light="true" @clicked="onTagClick"></tag-buttons>
    </div>
    <button @click="show = !show" class="togglebutton">
      <span>{{ show ? 'Close' : 'Open' }} Tags Manager</span>
      <svg title="Toggle Tags Manager" class="bs-icon" :class="show ? '-nudge-icon' : '-large-icon'">
        <use v-bind:xlink:href="'/static/symbols.svg#' + (show ? 'i-close' : 'i-ellipsis-horizontal')" />
      </svg>
    </button>
  </nav>
</template>

<script>
/* eslint-disable no-param-reassign */
import TagButtons from '../../../misc/TagButtons';

export default {
  name: 'TagsManager',
  data() {
    return {
      show: false,
      tags: [],
    };
  },
  created() {
    bus.bindAll(this, {
      'bucket:tags-update': this.updateTags,
      'tags-manager:refresh': this.refresh,
    });
  },
  beforeDestroy() {
    bus.unbindAll(this);
  },
  methods: {
    updateTags(tags) {
      this.tags = tags;
    },
    async onTagClick({ /* tagName, */ i }) {
      // if the first tag ('+') was clicked, add tag
      if (i === 0) {
        bus.$emit('tags-manager:create-tag');
        return;
      }
      const tagRefClicked = this.tags[i - 1];
      // get the full tag (with the description)
      const tagFull = await global.bucket.fetchTag({ tagName: tagRefClicked });
      bus.$emit('tags-manager:edit-tag', tagFull);

      // add description to tags:
      // DONE 1) in creating tags (CreditEditTag.vue)
      // have PUT ASIDE b/c of mysterious 500 error:
      //    2) add editing tags from click in TagsManager to edit descriptions
      // DONE on 5.23.17: added + impl. optional caption in TagButtons,
      // edited layout of EditBucketTags, and added guide line next to opened
      // entries in bucket (b/c they're longer, they lacked visual identity as units)

      // DONE add validation to the form,
      // DONE and add validation to the API for the fields:
      //    name: alphanumeric and _ and -
      //    (description: no validation)

      // DONE impl. destroying tags
    },
    refresh() {
      global.bucket.loadTagNames();
    },
    openExplorer() {
      bus.$emit('open-explorer'); // handled in Raudio
    },
  },
  components: { TagButtons },
};
</script>

<style lang="scss" scoped>
.tags-manager {
  padding: 0;
  justify-content: center;
  flex-wrap: wrap;
  background-color: rgba(205, 92, 92, 0.8);
  width: 65%;
  min-width: 20rem;
  max-width: 37rem;

  .togglebutton {
    width: 100%;
    height: 1.5rem;
    padding: 0.6em;

    > span {
      margin-right: -1em;
    }

    > .bs-icon {
      position: relative;
      top: 0.15em;
      margin-top: -2rem;
    }
  }

  .manager {
    width: 100%;
    padding: 0.5em;
  }

  // .manager {
  //   margin-top: -5rem;
  //   transition: all 0.3s ease;
  // }

  // &.open .manager {
  //   margin-top: 0;
  // }
  
  .explorerbutton {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    right: 0.25em;
    top: calc(0.25em + 0.5px);
    font-size: 1.2em;
    // width: 1.25em;
    margin: -0.25em -0.25em 0 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.1em 0.3em 0.35em 0.35em;
    > svg {
      transform: translateY(20%);
    }
  }
}
</style>