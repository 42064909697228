/* eslint-disable no-console, no-param-reassign, new-cap, no-use-before-define,
  no-restricted-syntax */
/* global sigma */

import containsAll from './containsAll';

// TODO:

// FUNCTIONALITIES:
// 1. load from actual bucket - DONE
// 2. On track node click, display a floating (in a corner) Entry.vue for the track
// (fetch the data live thru SC...) - DONE
// * 3. search by track name

// UI IMPROVEMENTS:
// disable dblclick to zoom in - DONE
// add css no-select to all the text on the page - DONE
// * make the andOperation toggle into a toggle switch (that displays selected AND or OR)
// * make edges get hot when matched? (make them black?)
// hide unused tags (so they don't interfere with the physics) - DONE
// should we display unused tags somewhere? (in the alt/title hover box for the stat box?)

// CONFIG
const sigmaSettings = {
  font: 'Varela Round',
  labelSizeRatio: 1.5,
  doubleClickEnabled: false,
};

// OTHER VARS

// init
let s;

const tagNodeIds = [];

// creating the graph
export const localBucket = {};
export const stats = {
  untaggedTracksCount: 0,
  unusedTagsCount: 0,
};

// a wrapper of sorts

export function setup({ bucket, container: el, renderOptions }) {
  inspectMode.active = false;
  inspectMode.andOperation = false;
  initSigma();
  loadBucket(bucket);
  initRenderer(el);
  bindEvents();
  initCustomRendering(renderOptions);
}

// inits

function initSigma() {
  s = new sigma({ settings: sigmaSettings });
  return s;
}

let container;
function initRenderer(_container) {
  container = _container;
  s.addRenderer({
    container,
    type: sigma.renderers.canvas,
  });

  s.refresh();

  s.configForceAtlas2({
    scalingRatio: 50,
    // edgeWeightInfluence: 1,
    // outboundAttractionDistribution: true,
    // linLogMode: true,
    // adjustSizes: true,
    // worker: false,
  });

  // const dnListener = sigma.plugins.dragNodes(s, s.renderers[0]);

  // dnListener.bind('drop', () => {
  //   s.startForceAtlas2();
  //   setTimeout(() => {
  //     s.stopForceAtlas2();
  //   }, 50);
  // });
}

function bindEvents() {
  // display pointer cursor over track nodes
  s.bind('overNode', (e) => {
    if (e.data.node.type === 'track') container.style.cursor = 'pointer';
  });
  s.bind('outNode', () => {
    container.style.cursor = 'auto';
  });

  // handle clicks on nodes
  s.bind('clickNode', async (e) => {
    const node = e.data.node;
    if (node.type === 'tag') {
      onTagNodeClick(node);
    } else if (node.type === 'track') {
      // window.open('http://api.soundcloud.com/tracks/' + node.id.slice(1) + '/stream?client_id=f239372ae6ef9d5f58324cb0effcbb21', '_blank')
      const entry = await SC.get('/tracks', {
        ids: node.id.slice(1),
      });
      bus.$emit('tagsExplorer:display-entry', entry);
    }
  });
  // handle clicks on stage that are within the bounds of a tag displayed as a tag node click
  const canvas = document.getElementsByClassName('sigma-scene')[0];
  s.bind('clickStage', ({ data: { captor } }) => {
    const x = captor.x + (canvas.width / 2);
    const y = captor.y + (canvas.height / 2);
    const targetTagNode = s.graph.nodes().find((n) =>
      (n.type === 'tag' &&
      x >= n.leftBound && x <= n.rightBound && y >= n.topBound && y <= n.bottomBound));

    if (targetTagNode) onTagNodeClick(targetTagNode);
  });
}

function initCustomRendering({ labelXPadding, labelYPadding }) {
  sigma.canvas.nodes.tag = function newTagNodeDef(node, context, settings) {
    const prefix = settings('prefix') || '';
    const size = node[`${prefix}size`] * 1.25;

    const fontSize = settings('labelSizeRatio') * size;
    context.font = `${fontSize}px "${settings('font')}"`;
    const labelWidth = context.measureText(node.label).width;
    const labelHeight = fontSize;

    const xPadding = (labelXPadding * size / 5);
    const yPadding = (labelYPadding * size / 5);

    if (inspectMode.active) {
      context.fillStyle =
        (node.hot ? (node.activeColor || settings('defaultNodeColor')) : node.disabledColor);
    } else {
      context.fillStyle = node.color || settings('defaultNodeColor');
    }

    node.leftBound = node[`${prefix}x`] - labelWidth / 2 - xPadding;
    const width = labelWidth + xPadding * 2;
    node.topBound = node[`${prefix}y`] - labelHeight / 2 - yPadding;
    const height = labelHeight + yPadding * 2;

    context.beginPath();
    context.rect(
      node.leftBound,
      node.topBound,
      width,
      height,
    );

    node.rightBound = node.leftBound + width;
    node.bottomBound = node.topBound + height;

    context.closePath();
    context.fill();
    context.strokeStyle = '#fff';
    context.strokeWidth = '5px';
    context.stroke();

    context.fillStyle = '#585858';
    context.fillText(
      node.label,
      node[`${prefix}x`] - labelWidth / 2,
      node[`${prefix}y`] + labelHeight / 3,
    );
  };

  sigma.canvas.nodes.track = function newTrackNodeDef(node, context, settings) {
    const prefix = settings('prefix') || '';

    if (inspectMode.active) {
      context.fillStyle =
        (node.hot ? (node.color || settings('defaultNodeColor')) : node.disabledColor);
    } else {
      context.fillStyle = node.color || settings('defaultNodeColor');
    }

    context.beginPath();
    context.arc(
      node[`${prefix}x`],
      node[`${prefix}y`],
      node[`${prefix}size`],
      0,
      Math.PI * 2,
      true
    );

    context.closePath();
    context.fill();
  };

  const originalLabelsDef = sigma.canvas.labels.def;
  sigma.canvas.labels.def = function newLabelsDef(node, context, settings) {
    if (node.type === 'tag') return;
    originalLabelsDef(node, context, settings);
  };

  const originalHoversDef = sigma.canvas.hovers.def;
  sigma.canvas.hovers.def = function newHoversDef(node, context, settings) {
    if (node.type === 'tag') return;
    originalHoversDef(node, context, settings);
  };
}

// creating the graph

function addTrackNode({ trackId, tags } = {}, i) {
  if (!(trackId.toString()).length) throw new Error('missing something');
  if (!tags || !tags.length) {
    stats.untaggedTracksCount++;
    return;
  }
  const trackNodeId = `T${trackId}`;
  s.graph.addNode({
    type: 'track',
    id: trackNodeId,
    label: trackId, // should this be track name in the future?
    x: i,
    y: 0,
    size: 5,
    color: '#cd5c5c',
    disabledColor: 'rgba(205, 92, 92, 0.2)',
    hot: false,
  });
  let tagNodeId;
  tags.forEach((tag) => {
    if (typeof tag === 'object' && tag.id) {
      tagNodeId = `C${tag.id}`;
    } else {
      tagNodeId = `C${tag}`;
    }
    if (tagNodeIds.includes(tagNodeId)) {
      s.graph.addEdge({
        id: `${trackNodeId}_${tagNodeId}`,
        source: trackNodeId,
        target: tagNodeId,
        color: '#777',
      });
    }
  });
}
function addTagNode(tag, i) {
  let id;
  let name;
  if (typeof tag === 'object') {
    id = tag.id;
    name = tag.name;
  } else {
    id = name = tag;
  }
  if (!id.length || !name.length) throw new Error('missing something');
  const tagNodeId = `C${id}`;
  tagNodeIds.push(tagNodeId);
  s.graph.addNode({
    type: 'tag',
    id: tagNodeId,
    label: name,
    x: i * 50,
    y: 200,
    size: 10,
    color: 'rgba(186, 218, 85, 0.8)',
    activeColor: 'rgba(186, 218, 85, 0.9)',
    disabledColor: 'rgba(255, 255, 255, 0.8)',
    hot: false,
  });
}
function removeUnusedTagNodes() {
  const edges = s.graph.edges();
  s.graph.nodes().forEach((n) => {
    if (n.type === 'tag') {
      let attachedEdge;
      for (let i = 0; i < edges.length; i++) {
        if (edges[i].target === n.id) {
          attachedEdge = edges[i];
          break;
        }
      }
      if (!attachedEdge) {
        stats.unusedTagsCount++;
        s.graph.dropNode(n.id);
      }
    }
  });
}

function loadBucket({ tracks, tags }) {
  s.graph.clear();
  stats.untaggedTracksCount = 0;
  stats.unusedTagsCount = 0;

  localBucket.tracks = tracks;
  localBucket.tags = tags;

  tags.map(addTagNode);
  tracks.map(addTrackNode);

  removeUnusedTagNodes();

  s.refresh();
  s.startForceAtlas2();
  setTimeout(() => {
    s.stopForceAtlas2();
    s.refresh();
  }, 10000);
}

export function getNodes(fn) {
  if (typeof fn === 'function') return s.graph.nodes().filter(fn);
  return s.graph.nodes();
}

// filtering

const inspectMode = {
  active: false,
  andOperation: false,
};

const publicInspectMode = new Proxy(inspectMode, {
  set(target, prop, value) {
    // if exiting inspect mode, make all the nodes not hot
    if (prop === 'active' && value === false) {
      s.graph.nodes().forEach((n) => {
        if (n.type === 'tag' && n.hot) {
          n.hot = false;
        }
      });
    }

    target[prop] = value;
    if (prop === 'active' || prop === 'andOperation') updateFiltering();

    return true;
  },
});
export { publicInspectMode as inspectMode };

function markFilteredTrackNodes() {
  const edges = s.graph.edges();
  const nodes = s.graph.nodes();

  // mark all tracks as not hot
  nodes.forEach((n) => {
    if (n.type === 'track') n.hot = false;
  });

  const selectedTagNodes = nodes.filter((n) => (n.type === 'tag' && n.hot));

  // for each tag, get all the edges pointing to the tag node
  const nodeIdsForTagsForAndOp = [];
  let filteredEdges;
  selectedTagNodes.forEach(({ id: targetTagId }, i) => {
    filteredEdges = edges.filter((edge) => (edge.target === targetTagId));

    // if we're going to do and operation,
    if (inspectMode.andOperation) {
      // let's get all the nodes attached to these edges into the `nodeIdsForTagsForAndOp` array
      nodeIdsForTagsForAndOp[i] = filteredEdges.map((edge) =>
        nodes.find((n) => (n.id === edge.source)).id);
      // (so we can do a union operation to get the highlighting)
    } else {
      let attachedNode;
      filteredEdges.forEach((edge) => {
        attachedNode = nodes.find((n) => (n.id === edge.source));
        attachedNode.hot = true;
      });
    }
  });

  if (inspectMode.andOperation) {
    const matchingNodeIds = containsAll(nodeIdsForTagsForAndOp);
    matchingNodeIds.forEach((targetNodeId) => {
      s.graph.nodes(targetNodeId).hot = true;
    });
  }
}

// used on andOperation toggle
function updateFiltering() {
  markFilteredTrackNodes();
  s.refresh(); // necessary for update on mobile (otherwise, the update waits until zoom)
}

function onTagNodeClick(node) {
  if (!inspectMode.active) {
    inspectMode.active = true;
    node.hot = true;
  } else {
    node.hot = !node.hot;
    const indexOfHotNode = s.graph.nodes()
      .findIndex((n) => (n.type === 'tag' && n.hot === true));
    if (indexOfHotNode === -1) inspectMode.active = false;
  }

  const hotTagNodes = s.graph.nodes()
    .filter((n) => (n.type === 'tag' && n.hot))
    .map((n) => n.label);
  bus.$emit('tagsExplorer:selected-tags', hotTagNodes);

  updateFiltering();
}
