/* eslint-disable camelcase, no-console, no-cond-assign, no-restricted-syntax */
import 'whatwg-fetch';

const querystringify = require('querystring').stringify;
const Fuse = require('fuse.js');

const api = (oauth_token) => ({
  OPTIONS: {
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'text/plain',
    },
  },
  request(method, path, body, queryParams) {
    if (!oauth_token || !/^[\d\w-]+$/.test(oauth_token)) {
      global.router.push('/auth');
      setTimeout(() => {
        global.throwErr(null, 'invalid oauth token');
      }, 1000);
      global.oauth_token = null;
      return Promise.reject('invalid oauth token');
    }

    const uri = `${global.BUCKET_API_BASE}${path}?${
      querystringify(Object.assign({ oauth_token }, queryParams))}`;
    // method must be uppercase because PATCH request requires it
    const options = Object.assign(this.OPTIONS,
      { method: method.toUpperCase(), body });
    return window.fetch(new Request(uri, options))
      .then(async (response) => {
        const jsonResponse = await response.json();
        if (jsonResponse.error) {
          global.throwErr(jsonResponse, jsonResponse.error);
          return false;
        }
        return jsonResponse;
      })
      .catch(global.throwErr);
  },
});

// maybe... to ensure that local bucket is synced with db, after any operation,
// let's compare a local and remote hash of the bucket? (if they don't match, re-sync)

// for searching in tag names (fusejs is a fuzzy search lib)
const fuseTagsSearchOptions = {
  shouldSort: true,
  // findAllMatches: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['name'],
  id: 'name',
};

export default (oauth_token) => ({
  API: api(oauth_token),
  page: 0,
  allTracks: [],
  tags: [],
  isHidden: true,
  password: null,
  userId: null,
  loadBucket({ userId, bucketPassword } = {}) {
    const url = (userId ? `/tracks/user/${userId}/${bucketPassword || ''}` : '/tracks/');
    this.load = this.API.request('get', url)
      .then((response) => {
        const isRealObj = (obj) => obj && typeof obj === 'object';
        if (isRealObj(response)) {
          if (isRealObj(response.result)) {
            this.allTracks = response.result;
          }
          if (isRealObj(response.meta)) {
            this.password = response.meta.password;
            this.userId = response.meta.userId;
            this.isHidden = response.meta.hidden;
          }
        }
      });
    return this.load;
  },
  loadTagNames() {
    this.loadTags = this.API.request('get', '/tags/')
      .then((response) => {
        if (response && typeof response === 'object') {
          this.tags = response;
          // this.allTagsNameSearch = this.createTagNamesSearchFn(this.tags);
          bus.$emit('bucket:tags-update', this.tags);
        }
      });
    return this.loadTags;
  },
  push(trackId) {
    return this.API.request('post', `/tracks/${trackId}`)
      .then((response) => {
        if (response) {
          let track;
          // if the track has been restored from trash, use that
          if (response.track) track = response.track;
          else track = { trackId, note: '', tags: [] };

          const trackIndexInBucket = this.allTracks.findIndex(
            ({ trackId: trackIdInBucket } = {}) => (trackId === trackIdInBucket));
          if (trackIndexInBucket >= 0) {
            this.allTracks[trackIndexInBucket].removed = false;
          } else {
            this.allTracks.push(track);
          }
        }
        return response;
      });
  },
  remove(trackId) {
    return this.API.request('delete', `/tracks/${trackId}`)
      .then((response) => {
        const trackIndexInBucket = this.allTracks.findIndex(
          ({ trackId: removedTrackId } = {}) => (trackId === removedTrackId));
        if (trackIndexInBucket >= 0) {
          this.allTracks[trackIndexInBucket].removed = true;
        }
        return response;
      });
  },
  editNote(trackId, newNoteVal) {
    return this.API.request('patch', `/tracks/${trackId}/note`, newNoteVal)
      .then((response) => {
        if (response) {
          const track = this.allTracks.find((t) => t.trackId === trackId);
          track.note = newNoteVal;
          bus.$emit('entry:update-note', track);
        }
      });
  },
  addTagToTrack(trackId, tagName) {
    return this.API.request('post', `/tracks/${trackId}/tags`, tagName)
      .then((response) => {
        if (response) {
          const track = this.allTracks.find((t) => t.trackId === trackId);
          // only push tag to track in allTracks if not there yet
          if (!track.tags) track.tags = [tagName];
          else if (track.tags.indexOf(tagName) === -1) track.tags.push(tagName);
          bus.$emit('entry:update-tags', track);
        }
      });
  },
  removeTagFromTrack(trackId, tagName) {
    return this.API.request('delete', `/tracks/${trackId}/tags`, tagName)
      .then((response) => {
        if (response) {
          const track = this.allTracks.find((t) => t.trackId === trackId);
          if (!track.tags) track.tags = [];
          // remove all instances of the tag in the track's `tags`
          let index;
          while ((index = track.tags.indexOf(tagName)) > -1) track.tags.splice(index, 1);
          bus.$emit('entry:update-tags', track);
        }
      });
  },
  getIsAdded(trackId) {
    return this.load.then(() => this.allTracks.some((t) => t.trackId === trackId));
  },

  createTag({ tagName, tagDescription }) {
    return this.loadTags.then(() => {
      if (this.tags.includes(tagName)) {
        return global.throwErr({}, `cannot create tag '${tagName}': tag already exists`);
      }
      return this.API.request('post', `/tags/${tagName}`, tagDescription);
    }).then((response) => {
      if (response) {
        this.tags.push(tagName);
        bus.$emit('bucket:tags-update', this.tags);
      }
    });
  },
  editTag({ tagName, tagDescription }) {
    return this.loadTags.then(() => {
      if (!this.tags.includes(tagName)) {
        return global.throwErr({}, `cannot edit tag '${tagName}': tag does not exist`);
      }
      return this.API.request('patch', `/tags/${tagName}`, tagDescription);
    });
  },
  destroyTag({ tagName }) {
    return this.loadTags.then(() => {
      if (!this.tags.includes(tagName)) {
        return global.throwErr({}, `cannot destroy tag '${tagName}': tag does not exist`);
      }
      return this.API.request('delete', `/tags/${tagName}`);
    }).then((response) => {
      if (response) {
        // remove all instances of the tag name in `tags`
        let index;
        while ((index = this.tags.indexOf(tagName)) > -1) this.tags.splice(index, 1);
        bus.$emit('bucket:tags-update', this.tags);
      }
    });
  },
  fetchTag({ tagName }) {
    return this.loadTags.then(() => this.API.request('get', `/tags/${tagName}`));
  },

  // returns a promise that should resolve a page
  list() {
    const page = this.page;
    return this.load.then(() => ({
      // replace this with hook's code for it
      result: this.allTracks.slice(page * 10, page * 10 + 10),
      lastPage: !this.allTracks[page * 10 + 11],
    }));
  },

  updatePassword(newPassword) {
    return this.load.then(() => this.API.request('post', '/meta/changepassword/', newPassword))
      .then((request) => {
        this.password = newPassword;
        this.isHidden = false;
        return request;
      });
  },

  makeHidden() {
    return this.load.then(() => this.API.request('post', '/meta/removepassword/'))
      .then(() => {
        this.isHidden = true;
        this.password = null;
      });
  },
  createTagNamesSearchFn(tags) {
    const searchList = tags.map((tagName) => ({ name: tagName }));
    const fuse = new Fuse(searchList, fuseTagsSearchOptions);
    return function fuseSearch(...args) {
      return fuse.search(...args);
    };
  },
  // in the future: index description too (not currently implementing this
  // because we're not keeping all the tags locally... we're only fetching
  // the metadata (description) for editing the tags. maybe in the future
  // we should keep an array of full tags locally and sync it with the server
  // when we need to, so that we can search in the descriptions; maybe we
  // will have to do the same thing with the bucket tracks if we are doing
  // bucket search)
});
