<script>
/* eslint-disable no-console */

export default {
  beforeRouteEnter() {
    localStorage.removeItem(global.SC_AUTH_TOKEN_STORAGE_KEY);
    // // delete global.oauth_token;
    // // remove SC then add a new instance back
    // // (seems like the only way to clear SC's internal oauth_token)
    // SC = null;
    // require('soundcloud'); // eslint-disable-line global-require

    global.router.push('/');
    // next();
    // window.location.href = '';
  },
};
</script>