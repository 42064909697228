<template>
  <div id="raudio-app">
    <div class="app">
      <button class="button-link backbutton" @click="exit">
        <svg title="Back" class="bs-icon -small-icon"><use xlink:href="/static/symbols.svg#i-chevron-left" /></svg><span>Back</span>
      </button>
      <h1>Viewing Bucket by {{ userIdStr }}</h1>
      <bucket-list v-if="userId" :user-id="userId" :bucket-password="bucketPassword" :read-only="true"></bucket-list>
    </div>
  </div>
</template>
<!-- TODO: Use the url to get the user ID and password -->
<script>
/* eslint-disable no-console */

import BucketList from './components/BucketList';

const bucketCreator = require('../bucket');

export default {
  name: 'ViewUserBucket',
  props: ['link'],
  data() {
    const [userIdStr, bucketPassword] = this.link.split('-');

    const maybeUserId = parseInt(userIdStr, 10);
    let promisedUserId;
    if (isNaN(maybeUserId)) {
      // this.redirectToBucketFromUsername(userIdStr);
      promisedUserId = this.getUserIdFromUsername(userIdStr);
    } else {
      promisedUserId = Promise.resolve(maybeUserId);
    }

    return {
      userIdStr,
      promisedUserId,
      // will be set to promisedUserId after bucket is loaded so that
      // the bucket always loads before the bucket list
      userId: null,
      bucketPassword,
    };
  },
  beforeCreate() {
    this.oauth_token = global.oauth_token
      || localStorage.getItem(global.SC_AUTH_TOKEN_STORAGE_KEY);

    if (!this.oauth_token) {
      global.router.replace('/auth');
      return;
    }

    global.oauth_token = this.oauth_token;

    SC.initialize({
      client_id: global.SC_CLIENT_ID,
      oauth_token: global.oauth_token,
      redirect_uri: global.SC_REDIRECT_URI,
    });
  },
  async created() {
    global.bucket = bucketCreator(global.oauth_token);

    const userId = await this.promisedUserId;
    global.bucket.loadBucket({ userId, bucketPassword: this.bucketPassword })
      .then(() => {
        this.userId = userId;
      });
  },
  beforeDestroy() {
    global.bucket.loadBucket();
  },
  methods: {
    async getUserIdFromUsername(username) {
      const user = await SC.get(`/users/${username}`);
      const userId = user.id;
      return userId;
    },
    redirectToBucketFromUsername(username) {
      SC.get(`/users/${username}`)
        .then((result) => {
          const newUrl = `/bucket/${result.id}${this.bucketPassword
            ? `-${this.bucketPassword}` : ''}`;
          global.router.forward(newUrl, () => {
            console.log(this.link);
            this.loadBucket();
            this.userId = this.link.split('-')[0];
          });
        })
        .catch((err) => {
          global.throwErr(err, 'username not on soundcloud');
        });
    },
    exit() {
      global.router.push('/app');
    },
  },
  components: { BucketList },
};
</script>

<style lang="scss" scoped>

// app layout //
#raudio-app {
  position: relative;
  width: 100%;
  font-size: 1rem;
  font-family: "Varela Round", sans-serif;
  
  > .app {
    width: 65%;
    min-width: 20em;
    margin: auto;
    padding: 1em;
  }
}

button {
  border: 0;
}

button.backbutton {
  > span {
    margin-left: 10px;
  }
}

#bucket-list {
  width: 100%;
  max-width: unset;
  top: 0.5em;
}
</style>