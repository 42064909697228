/* eslint-disable no-new */

import Vue from 'vue';
import VueRouter from 'vue-router';
import VueAnalytics from 'vue-ua';

Vue.use(VueRouter);

//
import App from './App';

import Home from './components/Home';
import Auth from './components/Auth';
import Raudio from './components/Raudio';
import Settings from './components/Settings';
import ViewUserBucket from './components/ViewUserBucket';
import PageNotFound from './error/PageNotFound';
//
import Logout from './interstitial/Logout';
//

global.router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: Home },
    { path: '/auth', component: Auth },
    { path: '/app', component: Raudio },
    { path: '/settings', component: Settings },
    { path: '/bucket/:link', component: ViewUserBucket, props: true },
    { path: '/logout', component: Logout },
    { path: '*', component: PageNotFound },
  ],
});

new Vue({
  el: '#app-wrapper',
  router: global.router,
  render: h => h(App),
});

Vue.use(VueAnalytics, {
  appName: 'raudio',
  appVersion: '0.0.2',
  trackingId: 'UA-43248745-7',
  debug: false,
  vueRouter: global.router,
});
