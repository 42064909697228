<template>
  <div id="bucket-list">
    <div v-if="!loaded">Loading bucket...</div>
    <template v-if="newEntries.length || entries.length">
      <ul class="track-list -no-bullets">
        <entry v-for="entry in newEntries" :entry="entry" :type="entry.kind" :default-added="true" :read-only="readOnly" @ended="playerEnded" list-name="bucket-list"></entry>
      </ul>
      <ul class="track-list -no-bullets">
        <entry v-for="entry in entries" :entry="entry" :type="entry.kind" :default-added="true" :read-only="readOnly" @ended="playerEnded" list-name="bucket-list"></entry>
      </ul>
    </template>
    <blank-state v-else-if="loaded && !erred && !readOnly">Your bucket is empty.<br>Add tracks with the
      <svg v-show="isAdded !== true" title="Add to Bucket" class="bs-icon">
        <use xlink:href="/static/symbols.svg#i-plus" />
      </svg> button</blank-state>

    <div v-if="erred">Error loading bucket</div>
    <div v-show="loaded && canLoadMore" class="load-more" v-cloak>
      <a @click="loadNextPage">{{ erred ? 'Try again' : 'Load more' }}</a>
      <span class="loading" v-show="loadingMore"></span>
    </div>
    <p class="endnote" v-if="!loadingMore && !canLoadMore && (newEntries.length || entries.length)">You've hit the bottom of {{ userId ? 'this' : 'your' }} bucket.</p>
  </div>
</template>

<script>
/* eslint-disable no-console, no-param-reassign */
import Entry from './components/Entry';
import List from './List';
import BlankState from '../../misc/BlankState';

//

export default {
  name: 'BucketList',
  data() {
    return {
      loadingMore: false,
      canLoadMore: true,
      newEntries: [],
      stale: false,
    };
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      default: null,
    },
    bucketPassword: {
      type: String,
      default: null,
    },
  },
  mixins: [List],
  created() {
    bus.bindAll(this, {
      'bucket-list:refresh': this.customRefresh,
      'entry:updateAdded': this.onEntryUpdateAdded,
    });
  },
  beforeDestroy() {
    bus.unbindAll(this);
  },
  methods: {
    async customRefresh() {
      global.bucket.page = 0;
      this.canLoadMore = true;
      this.newEntries = [];
      this.stale = true;
      if (!this.readOnly) bus.$emit('tags-manager:refresh');
      await this.refresh();
    },
    // for List
    async fetchTracks() {
      if (this.stale) {
        // for own bucket, userId prop is null; for public bucket, it is the other user's id
        await global.bucket.loadBucket({
          // userId will be a promise, since it could have been fetched from username asyncly
          userId: await this.userId,
          bucketPassword: this.bucketPassword,
        });
        this.stale = false;
      }
      const response = await global.bucket.list();
      if (response.error === 'bucket not found') {
        this.canLoadMore = false;
        return [{ message:
          'Your bucket is empty. You can add a track by clicking on the + icon.' }];
      }
      if (response.lastPage === true) this.canLoadMore = false;

      // array of objects with trackIds[, notes, and tags]
      const tracks = response.result;
      const trackIds = tracks.map((track) => track.trackId);
      // array of SC API entry documents
      const entries = await SC.get('/tracks', {
        ids: trackIds.join(',')
      });
      // since the order of the result might not be the same as the order in
      // the 'ids' param, let's sort the entries to the order in `tracks`.
      const sortedEntries = [];
      entries.forEach(({ id }, i) => {
        sortedEntries[trackIds.indexOf(id)] = entries[i];
      });
      // let's now add the bucket notes and tags into the entries
      sortedEntries.forEach((entry, i) => {
        if (typeof tracks[i].note !== 'undefined') entry.note = tracks[i].note;
        if (typeof tracks[i].tags !== 'undefined') entry.tags = tracks[i].tags;
      });
      return sortedEntries;
    },
    // for List
    filterData(tracks) {
      return tracks.filter((a) => a != null);
    },
    async loadNextPage() {
      if (!this.canLoadMore) return;
      if (this.loadingMore === true && this.erred === false) return;
      this.loadingMore = true;
      this.erred = false;
      global.bucket.page++;
      await this.updateList();
      this.loadingMore = false;
    },
    // onEntryUpdateAdded({ entry, newStatus }) {
    //   bus.$emit('entry:updateAdded', { entry, newStatus });
    // },
    // coming only from an event from ActivitiesList
    onEntryUpdateAdded({ entry, newStatus, listName, oldStatus }) {
      // ignore initial updates when setting isAdded at the beginning
      if (oldStatus == null) return;
      // if the update is from the bucket list, don't touch newEntries
      if (listName === 'bucket-list') return;

      const indexInEntries = this.entries.findIndex((entry_) => entry_.id === entry.id);
      const indexInNewEntries = this.newEntries.findIndex((entry_) => entry_.id === entry.id);
      // if the entry is now added and it is in neither part of the bucket, add it to newEntries
      if (newStatus === true && indexInEntries === -1 && indexInNewEntries === -1) {
        this.newEntries = [entry].concat(this.newEntries);
      }
    },
  },
  components: { Entry, BlankState },
};
</script>

<style lang="scss" scoped>
#bucket-list {
  width: 65%;
  min-width: 20rem;
  max-width: 37rem;
  margin: auto;
  transition: .2s top ease;
  position: relative;
  top: 2em; // for the tags manager
}

.track-list {
  padding: 0;
}

.track-list > li {
  margin-bottom: 1em;
}

.endnote {
  font-size: 0.9em;
  color: #888;
  text-align: center;
}

.load-more {
  position: relative;
  width: 100%;
  text-align: center;
  > a {
    cursor: pointer;
  }
}
</style>