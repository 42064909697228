<template>
  <!-- <nav class="options-bar {{ $parent.$parent.showingBucket ? 'accent' : '' }}" v-show="optionsExpanded" @click="$parent.optionsExpanded = false"> -->
  <nav class="options-bar" @click="$parent.optionsExpanded = false">
    <!-- <nav-button title="Refresh" @click="$parent.refreshList"></nav-button> -->
    <nav-button title="Settings" link="/settings"></nav-button>
    <!-- <nav-button :title="$parent.$parent.showingBucket ? 'Close Bucket' : 'View Bucket'" @click="$parent.$parent.showingBucket = !$parent.$parent.showingBucket"></nav-button> -->
    <nav-button title="View Bucket" global-event="toggleBucket"></nav-button>
    <nav-button title="Logout" link="/logout" :accent="true"></nav-button>
  </nav>
</template>

<script>
import NavButton from './components/NavButton';

export default {
  name: 'OptionsBar',
  components: { NavButton },
};
</script>

<style lang="scss" scoped>
.options-bar {
  justify-content: flex-end;
  background-color: rgba(51, 51, 51, 0.8);
  // max-width: 40rem;
  padding: .6em;

  // &.accent {
  //   background-color: rgba(205, 92, 92, 0.8);
  // }

  > .navitem {
    margin: 0 0.1em;
  }
}
</style>