<template>
  <div id="auth">
    <div>
      <h1>Login with SoundCloud</h1>
      <div>
        <p>Log into raudio by logging into SoundCloud.</p>
        <button @click="login">
          <img src="../assets/img/soundcloud-btn-connect-l.png" title="Connect to SoundCloud">
        </button>
        <!-- X-Frame-Options: SAMEORIGIN for the url, so this fallback for android doesn't work -->
        <!-- <iframe v-show="androidHackShowIframe" height="500px" width="100%" style="border: 2px solid black;" :src="androidHackShowIframe ? androidHackIframeUrl : ''"></iframe> -->
      </div>
      <div><i>or</i></div>
      <div>
        <button @click="manualConnectToggle = !manualConnectToggle" class="btn small">I have a connect-code</button>
        <div v-show="manualConnectToggle">
          <input type="text" placeholder="insert code here" v-model="manualCode">
          <button @click="manualConnect" class="btn accent">Connect</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console, camelcase */

export default {
  name: 'Auth',
  data() {
    return {
      manualConnectToggle: false,
      manualCode: '',
      // 5-20-23 see details in comment above, this hack isn't being used anymore
      usingAndroid: false, // /Android/.test(window.navigator.userAgent),
      androidHackShowIframe: false,
      androidHackIframeUrl: '' //`https://corsproxy.io/?` + encodeURIComponent(`https://soundcloud.com/connect?client_id=${global.SC_CLIENT_ID}&redirect_uri=${global.SC_REDIRECT_URI}&response_type=token&scope=non-expiring`),
    };
  },
  mounted() {
    window.scHackCallback = (response) => {
      console.log(response);
      this.androidHackShowIframe = false;
    };
  },
  route: {
    beforeRouteEnter(to, from, next) {
      const oauth_token = localStorage.getItem(global.SC_AUTH_TOKEN_STORAGE_KEY);
      if (oauth_token) {
        this.proceedToApp();
        next(false);
      } else next();
    },
  },
  methods: {
    login() {
      SC.initialize({
        client_id: global.SC_CLIENT_ID,
        redirect_uri: global.SC_REDIRECT_URI,
      });

      if (this.usingAndroid) {
        this.androidHackShowIframe = true;
      } else {
        SC.connect().then(({ err, oauth_token }) => {
          if (err) {
            global.throwErr(err, 'Could not connect to SoundCloud');
            return;
          }
          localStorage.setItem(global.SC_AUTH_TOKEN_STORAGE_KEY, oauth_token);
          this.proceedToApp();
        }).catch((err) => global.throwErr(err, 'Could not connect to SoundCloud'));
      }
    },
    manualConnect() {
      const oauth_token = atob(this.manualCode);
      localStorage.setItem(global.SC_AUTH_TOKEN_STORAGE_KEY, oauth_token);
      this.proceedToApp();
    },
    proceedToApp() {
      // console.log(oauth_token);
      global.router.push('/app');
    },
  },
};
</script>

<style lang="scss" scoped>
#auth {
  position: relative;
  width: 100%;
  
  > div {
    width: 70%;
    margin: auto;

    > div {
      margin-bottom: 1em;
    }

    .btn {
      padding: 0.5em;
      border: 1px solid #000;
      border-radius: 0.35em;
      margin-bottom: 0.3em;

      &.small {
        font-size: 0.8em;
      }

      &.accent {
        color: #5c5ccd;
        border-color: #5c5ccd;
      }
    }
  }
}
</style>
