<template>
  <audio controls :src="src" preload="none" @play="onPlay" @pause="onPause" @ended="onEnded" @error="onError"></audio>
  <!-- @timeupdate="onTimeUpdate" -->
</template>

<script>
/* eslint-disable no-console */

export default {
  name: 'AudioPlayer',
  props: ['src'],
  created() {
    bus.bindAll(this, {
      'audio-player:play': this.onSomePlay,
      'audio-player:autoplay': this.autoplay,
    });
  },
  beforeDestroy() {
    bus.unbindAll(this);
  },
  mounted() {
    this.$nextTick(() => {
      global.plyr.setup(this.$el, global.plyrSettings);
    });
  },
  methods: {
    onPlay() {
      bus.$emit('audio-player:play', { player: this.$el });
      this.$emit('play');
    },
    onError(e) {
      console.error(e, 'Error loading audio');
      // global.throwErr(e, 'Error loading audio');
      // plyr will just buffer indefinitely if it can't load the audio
    },
    onSomePlay({ player }) {
      // don't pause if this player is about to play;
      // (that would require another .play after dispatch in
      // `onPlay`, triggering the method itself again)
      if (this.$el !== player) this.$el.pause();
    },
    onPause() {
      this.$emit('pause');
    },
    onEnded() {
      // tell Entry to tell List to broadcast for the next entry to play (autoplay feature)
      // (auto-load a new page if necessary)
      // FIXME: I don't think .seeking is ever returning true when seeking
      if (global.userSettings.get('autoplayOn') && !this.$el.seeking) {
        this.$emit('ended');
      }
    },
    autoplay() {
      // this is only broadcast to the AudioPlayer in the next entry
      // (found in List's `entries`)
      this.$el.currentTime = 0;
      // note: catching errors both sync- and asynchronously because not all browsers
      // have .play implemented as asynchronous, returning a promise (9.5.16)
      try {
        this.$el.play()
          .catch((err) => this.catchAudioPlayError(err));
      } catch (err) {
        this.catchAudioPlayError(err);
      }
    },

    catchAudioPlayError(err) {
      if (err.name === 'NotAllowedError') {
        this.playWithReloadTrick();
      }
    },
    playWithReloadTrick() {
      // if on a mobile browser, we likely cannot play *programmatically*...
      // but maybe we can reload the player and have it "autoplay"!
      // update: welp, autoplay is gone for some mobile
      this.$el.autoplay = true;
      this.$el.src = undefined;
      setTimeout(() => {
        this.$el.src = this.src;
      }, 50);
    },
    // onTimeUpdate() {
      // // if within 8 seconds of the end,
      // if (this.$el.currentTime + 8 > this.$el.duration) {
      //   // tell Entry to tell List to preload the next track if it
      //   // exists (auto-load a new page if necessary)
      //   this.$dispatch('nearAutoplay:AudioPlayer');
      // }
    // },
  },
};
</script>

<style lang="scss">
.plyr {
  margin: 0.5em 0;
}

.plyr--loading .plyr__progress--buffer {
  background-image: linear-gradient(75deg,#fff 25%,transparent 25%,transparent 50%,#fff 50%,#fff 75%,transparent 75%,transparent) !important;
}

.plyr--audio .plyr__controls button {
  background: none !important;
  color: currentColor !important;
  outline: none !important;
}
</style>