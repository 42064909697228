/* eslint-disable no-console */

const userSettingsCollection = global.userDataDB.get('userSettings');

const defaultUserSettings = {
  autoplayOn: true,
  modes: {
    simplifyOneTrackPlaylists: true,
  },
};
global.userDataDB.defaultsDeep({ userSettings: defaultUserSettings }).value();

module.exports = {
  set(name, val) {
    return userSettingsCollection.set(name, val).value();
  },
  get(name) {
    return userSettingsCollection.value()[name];
  },
  getAll() {
    return userSettingsCollection.value();
  },
  update(newSettings) {
    for (const name in newSettings) { // eslint-disable-line no-restricted-syntax
      if (!newSettings.hasOwnProperty(name)) continue;

      this.set(name, newSettings[name]);
    }
  },
};
