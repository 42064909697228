<!-- merging CreateTag and EditTag! they have the same fields and validation -->

<template>
  <modal v-if="showing" :primary-button-name="editing ? 'Save' : 'Create Tag'" :disable-primary="invalid || tagName === ''" secondary-button-name="Cancel" accent-color="#cd5c5c" @success="onSuccess" @exit="showing = false">
    <h3 slot="header">{{ editing ? 'Edit Tag' : 'Create Tag' }}</h3>
    <p v-if="!editing"><i>You can edit this later</i></p>
    <div slot="body">
      <div v-if="!editing" class="field-group" :class="{ invalid }">
        <label class="block" for="note">Name</label>
        <input name="note" class="field" v-model="tagName" placeholder="electronic, ambient, etc." pattern="^[\w-]+$">
        <span class="errormsg">Name is invalid. It may contain only letters, numbers, -, and _.</span>
        <!-- <div v-show="lastNote.length > 500">{{ 600 - lastNote.length }} characters remaining</div> -->
      </div>
      <h4 v-else>{{ tagName }}</h4>
      <div class="field-group">
        <label class="block" for="note">Description</label>
        <textarea name="note" class="field -u-note-text" v-model="description" placeholder="Write description here..."></textarea>
        <div v-show="description.length > 500">{{ 600 - description.length }} characters remaining</div>
      </div>
      <div v-if="editing" class="field-group">
        <button @click="destroyTag()" class="dangerbtn">Destroy Tag</button>
      </div>
    </div>
  </modal>
</template>

<script>
/* eslint-disable no-console, no-alert */
import Modal from '../../misc/Modal';

export default {
  name: 'CreateEditTag',
  data() {
    return {
      editing: null, // boolean
      tagName: '',
      description: '',
      showing: false,
      invalid: false,
    };
  },
  created() {
    bus.bindAll(this, {
      'tags-manager:create-tag': this.createTag,
      'tags-manager:edit-tag': this.editTag,
    });
  },
  beforeDestroy() {
    bus.unbindAll(this);
  },
  watch: {
    tagName(newVal) {
      if (!/^[\w-]+$/.test(newVal) && newVal !== '') {
        if (!this.invalid) this.invalid = true;
      } else if (this.invalid) {
        this.invalid = false;
      }
    },
  },
  methods: {
    createTag() {
      this.editing = false;
      this.tagName = this.description = '';
      this.showing = true;
    },
    editTag(tag) {
      this.editing = true;
      this.tagName = tag.name;
      this.description = tag.description;
      this.showing = true;
    },
    destroyTag() {
      if (confirm(`Are you sure you want to permanently destroy the "${this.tagName}" tag?`)) {
        const prev = this.invalid;
        this.invalid = true;
        global.bucket.destroyTag({ tagName: this.tagName })
          .then(() => {
            this.showing = false;
          }).catch(() => {
            this.invalid = prev;
          });
      }
    },
    onSuccess() {
      if (this.invalid) return;
      const method = this.editing ? 'editTag' : 'createTag';
      global.bucket[method]({ tagName: this.tagName, tagDescription: this.description });
    },
  },
  components: { Modal },
};

</script>

<style lang="scss">
#note {
  resize: none;
  line-height: 1.5em;
}

.errormsg {
  display: none;
}
.field-group.invalid {
  .field {
    border: 1px solid red;
  }
  .errormsg {
    display: block;
    color: red;
  }
}

.dangerbtn {
  border-radius: 0.5em;
  padding: 0.5em;
  background-color: red;
  color: #fff;
}

</style>