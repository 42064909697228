import { render, staticRenderFns } from "./NavButton.vue?vue&type=template&id=bfa61200&scoped=true&"
import script from "./NavButton.vue?vue&type=script&lang=js&"
export * from "./NavButton.vue?vue&type=script&lang=js&"
import style0 from "./NavButton.vue?vue&type=style&index=0&id=bfa61200&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_vy273aczmdm3lffmyf7zmhkuji/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfa61200",
  null
  
)

export default component.exports