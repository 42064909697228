<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
/* eslint-disable max-len, no-console */

import Vue from 'vue';

import moment from 'moment';

const urlRgx = /((https?):\/\/)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)(?!.*data-generated)/g;
const userTagRgx = /@([\w-_]+)(?!.*data-generated)/g;
const hashtagRgx = /#([\w-_]+)(?!.*data-generated)/g;

global.filters = {
  getText(str) {
    const span = document.createElement('span');
    span.innerHTML = str;
    return span.textContent;
  },
  // link urls, artists, tags
  // using data-generated attribute to prevent subsequent replacement(s) from matching generated links
  autolink(text) {
    return text
      // 1) link hashtags
      .replace(hashtagRgx, '<a target="_blank" href="https://soundcloud.com/tags/$1" data-generated>$&</a>')
      // 2) link artist tags
      .replace(userTagRgx, '<a target="_blank" href="https://soundcloud.com/$1" data-generated>$&</a>')
      // 3) link urls
      .replace(urlRgx, (match, protocol) => `<a target="_blank" href="${protocol ? match : `http://${match}`}" data-generated>${match}</a>`);
  },
  convertLineBreaks(str) {
    return str.replace(/\n/g, '<br>');
  },
};

Vue.filter('readableTimeSince', (time) => moment(new Date(time)).fromNow());

//
global.uuid = require('uuid').v4;
global.scrollToElement = require('./lib/scrollTo');

//
import low from 'lowdb';

global.userDataDB = low('userDataDB');
global.userSettings = require('./userSettings');
//

import Bus from './Bus';
global.bus = new Vue(Bus);
if (process.env.NODE_ENV === 'development') {
  // HACK to log all $emit's
  const oldEmit = bus.$emit;
  bus.$emit = function newEmit(eventName, ...args) {
    console.log('[bus]', eventName);
    oldEmit.call(this, eventName, ...args);
  };
}

import Home from './components/Home';
import Auth from './components/Auth';
import Raudio from './components/Raudio';

//
if (process.env.NODE_ENV === 'development') {
  console.info('Env:', process.env);
}
//
//
global.SC_AUTH_TOKEN_STORAGE_KEY = 'sc:oauth_token';
global.SC_CLIENT_ID = process.env.VUE_APP_SC_CLIENT_ID;
global.SC_REDIRECT_URI = `http://${location.host}/static/sc-callback.html`;

global.apiBaseUrlRgx = /^https?:\/\/api\.soundcloud\.com/;

global.BUCKET_API_BASE = process.env.NODE_ENV === 'development'
  ? 'https://raudiohook-dev.ngrok.io'
  : 'https://raudio-hook.vercel.app';

export default {
  name: 'App',
  data() {
    return {};
  },
  // route: {
  //   activate({ next }) {
  //     SC.get('/me').then((result) => {
  //       const username = result.permalink;

  //       next();
  //     });
  //   },
  // },
  components: { Home, Auth, Raudio },
};
</script>

<style lang="scss">
@import url('../public/static/css/base.css');
@import url('https://fonts.googleapis.com/css?family=Varela+Round|Source+Code+Pro');

// reset button default styles
button {
  box-sizing: content-box;
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  outline: none;
  user-select: none;

  &::focus-inner {
      border: 0;
      padding: 0;
  }
}

// icons
.bs-icon, .plyr__controls svg, .control.icon svg {
  stroke: currentColor;
  stroke-width: 10.9375%;
  stroke-linecap: miter;
  stroke-linejoin: square;
  fill: none;
  width: 1em;
  height: 1em;
  // margin: 0.4em;
}

.bs-icon.-small-icon {
  width: 0.7em;
  height: 0.7em;
}

.bs-icon.-large-icon {
  font-size: 5em;
  stroke-width: unset;
  height: 2rem;
}

.bs-icon.-nudge-icon {
  left: 1.5em;
}

.plyr__controls {
  padding: 0 !important;
  border: 0 !important;
}

.plyr__controls svg, .control.icon svg {
  fill: currentColor;
}

.plyr__controls > .plyr__progress {
  height: 3px;
  top: 2px;

  > .plyr__progress--seek, .plyr__progress--played, .plyr__progress--buffer {
    height: 3px;
  }

  > .plyr__progress--seek {
    top: -2px;
  }

  > .plyr__progress--played {
    color: #777;
  }
}

.-u-note-text {
  font-family: "Source Code Pro", monospace;
}

/*.dots-loader::after {
  display: inline-block;
  font-size: 2em;
  position: relative;
  top: 0.33em;
  left: -0.33em;
  color: #cd5c5c;
  font-family: "dripicons-v2";
  content: " ";
  animation: loadingDots 0.66s steps(1) infinite;
}

@keyframes loadingDots {
  0% {
    content: "-"; // one dot
    left: 0;
  }
  33%, {
    content: "."; // two dots
    left: 0.14em;
  }
  66%, {
    content: "/"; // three dots
    left: 0.29em;
  }
}*/
</style>