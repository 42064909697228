<template>
  <div id="tags-explorer-container">
    <h1>Tags Explorer</h1>
    <button class="button-link backbutton" @click="exit">
      <svg title="Back" class="bs-icon -small-icon"><use xlink:href="/static/symbols.svg#i-chevron-left" /></svg><span>Return to Bucket List</span>
    </button>

    <tags-explorer-app></tags-explorer-app>
  </div>
</template>

<script>
/* eslint-disable no-console, no-param-reassign */
import TagsExplorerApp from './components/TagsExplorerApp';

//

export default {
  name: 'TagsExplorer',
  methods: {
    exit() {
      bus.$emit('close-explorer');
    },
  },
  components: { TagsExplorerApp },
};
</script>

<style lang="scss" scoped>
#tags-explorer-container {
  // width: 65%;
  // min-width: 20em;
  width: 62em;
  margin: auto;
  padding: 1em;
}
@media screen and (max-width: 62em) {
  #tags-explorer-container {
    width: 100%;
  }
}
</style>