/* eslint-disable no-console */

const util = require('util');

const MapCnt = function MapCnt(iterable = []) {
  if (!(this instanceof MapCnt)) {
    throw new TypeError("Constructor MapCnt requires 'new'");
  }
  const self = (Object.getPrototypeOf(this) === Map.prototype)
    ? this
    : new Map(iterable);
  Object.setPrototypeOf(self, MapCnt.prototype);

  return self;
};

util.inherits(MapCnt, Map);
Object.setPrototypeOf(MapCnt, Map);

MapCnt.prototype.count = function count(iterable) {
  // make sure items from the array are unique
  const set = new Set(iterable);
  // now update the cnt for each item in the set
  let cnt;
  for (const item of set) {
    cnt = this.get(item) || 0;
    ++cnt;
    this.set(item, cnt);
  }
};

export default function containsAll(allArrays) {
  const cntObj = new MapCnt();
  for (const array of allArrays) {
    cntObj.count(array);
  }
  // now see how many items have the full cnt
  const output = [];
  for (const [item, cnt] of cntObj.entries()) {
    if (cnt === allArrays.length) {
      output.push(item);
    }
  }
  return output;
}
