<template>
  <div id="raudio-app">
    <div class="app">
      <button class="button-link backbutton" @click="exit">
        <svg title="Back" class="bs-icon -small-icon"><use xlink:href="/static/symbols.svg#i-chevron-left" /></svg><span>Back</span>
      </button>
      <h1>Settings</h1>
      <hr>
      <h2>Basic</h2>
      <hr>
      <span class="checkbox">
        <label for="autoplayOn"><b>Autoplay</b></label>
        <input id="autoplayOn" type="checkbox" v-model="settings.autoplayOn" @change="updateUnsaved">
      </span>
      <p>When you reach the end of a track, automatically play the next one.</p>
      <p>If the track that has ended is before an opened playlist, autoplay will play the first track in it.  If the track that has ended is before an unopened playlist, autoplay will play the first track after the playlist.</p>
      <p><svg title="Info" class="bs-icon"><use xlink:href="/static/symbols.svg#i-info" /></svg>
      By default, most mobile devices do not support this feature. Using Chrome on a mobile device, you can enable this at <code class="-break-all">chrome://flags/#disable-gesture-requirement-for-media-playback</code>.</p>
      <h2>Modes</h2>
      <hr>
      <h3>Simplify One-Track Playlists</h3>
      <p>Display a playlist with only one track as one track in the activities list (not a playlist).</p>
      <p>Favoriting this entry adds the track without the playlist to the bucket.</p>
      <span class="checkbox">
        <label for="simplifyOneTrackPlaylists">Enable <b>Simplify One-Track Playlists</b></label>
        <input id="simplifyOneTrackPlaylists" type="checkbox" v-model="settings.modes.simplifyOneTrackPlaylists" @change="updateUnsaved">
      </span>
      <hr>
      <button class="button" @click="save">Save Changes</button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

import _ from 'lodash';

let oldSettings = global.userSettings.getAll();

const areYouSureDialogText = 'Are you sure you want to leave this page? You have unsaved changes.';

export default {
  name: 'Settings',
  data() {
    global.settingsChanged = false;
    return {
      settings: _.cloneDeep(oldSettings),
    };
  },
  methods: {
    updateUnsaved() {
      global.settingsChanged = !_.isEqual(oldSettings, this.settings);
    },
    save() {
      global.userSettings.update(this.settings);
      global.settingsChanged = false;
      oldSettings = this.settings;
    },
    exit() {
      if (global.settingsChanged) {
        // TODO: use modal system instead of `confirm`
        if (confirm(areYouSureDialogText)) { // eslint-disable-line no-alert
          global.router.push('/app');
        } else return;
      } else global.router.push('/app');
    },
  },
};

/* eslint-disable consistent-return */
window.onbeforeunload = function onbeforeunload(e) {
  if (global.settingsChanged) {
    const dialogText = 'Are you sure you want to leave this page? You have unsaved changes.';
    e.returnValue = dialogText; // eslint-disable-line no-param-reassign
    return dialogText;
  }
};
</script>

<style lang="scss" scoped>

// app layout //
#raudio-app {
  position: relative;
  width: 100%;
  font-size: 1rem;
  font-family: "Varela Round", sans-serif;
  
  > .app {
  	width: 65%;
    min-width: 20em;
    margin: auto;
    padding: 1em;
  }
}

ul.-no-bullets {
  list-style-type: none;
}

.-fill {
  fill: currentColor;
}

button {
  border: 0;
}

button.backbutton {
  > span {
    margin-left: 10px;
  }
}
</style>